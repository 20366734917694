import { j as i } from "../../jsx-runtime-B6kdoens.js";
import { useEffect as u } from "react";
import a, { keyframes as $ } from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-atf5CkC7.js";
import { getSpacings as t, getPaletteBorder as h, getRadii as b, getPaletteBackground as y, getEffects as v, getBreakpointMediaQuery as x, getZIndexes as j } from "../Theme/utils.es.js";
import "../../CssBaseline-XoOio97s.js";
import { I as N } from "../../IconButton-CyhPHivf.js";
import { I } from "../../CheckIcon-Dl7Gf-OR.js";
import "../Icon/countryVariants.es.js";
import { F as A } from "../../FileIcon-C0c-P2i2.js";
import "../FeaturedIcon/constants.es.js";
import "../../styles-DaQVIHg4.js";
import { T as g } from "../../Typography-Cc9k4gPj.js";
import { notificationItemAnimationKeyframes as T, notificationListStyles as w } from "./styles.es.js";
import { STATE_VARIANT_MAPPER as C } from "./constants.es.js";
import { u as P, N as E } from "../../hooks-DVuc7it1.js";
import { a as si } from "../../hooks-DVuc7it1.js";
const _ = a.div`
  display: flex;
  position: relative;
  width: 400px;
  box-sizing: border-box;
  
  animation-timing-function: ease-in-out;
  animation-duration: 300ms;
  
  animation-name: ${(o) => T[o.$position]};
  
  padding: ${t("xl")};
  border: 1px solid ${h("primary")};
  border-radius: ${b("xl")};
  background: ${y("primary.default")};
  box-shadow: ${v("shadows.lg")};
  
  ${x("max", "lg")} {
    width: 100%;
  }
`, k = a.div`
  display: flex;
  flex-direction: row;
  
  padding-right: ${t("4xl")};
  gap: ${t("xl")};
  
  ${x("max", "lg")} {
    flex-direction: column;
  }
`, z = a.div`
  display: flex;
  flex-direction: column;
  
  gap: ${t("lg")};
`, D = a.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  
  gap: ${t("xs")};
`, B = a(N)`
  position: absolute;
  top: 8px;
  right: 8px;
`, R = (o) => {
  const {
    title: r,
    message: e,
    variant: n,
    className: d,
    position: m = "bottom-left",
    children: c,
    autoCloseDuration: s,
    createdAt: l = Date.now,
    onClose: p
  } = o;
  return u(() => {
    let f;
    return p && s && (f = setTimeout(() => {
      p();
    }, s)), () => {
      clearTimeout(f);
    };
  }, [l]), /* @__PURE__ */ i.jsxs(
    _,
    {
      $position: m,
      className: d,
      children: [
        /* @__PURE__ */ i.jsx(
          B,
          {
            variant: "ghost",
            color: "gray",
            onClick: p,
            children: /* @__PURE__ */ i.jsx(
              I,
              {
                variant: "close"
              }
            )
          }
        ),
        /* @__PURE__ */ i.jsxs(k, { children: [
          n && /* @__PURE__ */ i.jsx(
            A,
            {
              size: "sm",
              variant: "outlined",
              color: n,
              iconProps: {
                variant: C[n]
              }
            }
          ),
          /* @__PURE__ */ i.jsxs(z, { children: [
            /* @__PURE__ */ i.jsxs(D, { children: [
              /* @__PURE__ */ i.jsx(
                g,
                {
                  size: "sm",
                  weight: "semibold",
                  children: r
                }
              ),
              e && /* @__PURE__ */ i.jsx(
                g,
                {
                  size: "sm",
                  weight: "regular",
                  children: e
                }
              )
            ] }),
            c
          ] })
        ] })
      ]
    }
  );
}, S = $`
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
`, F = a.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  position: fixed;
  
  animation: ease-in-out ${S} 150ms;
  
  gap: ${t("3xl")};
  padding: ${t("3xl")};
  z-index: ${j("notification")};
  
  ${x("max", "lg")} {
    width: 100%;
    background: linear-gradient(357deg, rgba(0, 0, 0, 0.10) 2.75%, rgba(0, 0, 0, 0.00) 92.77%);
    margin: 0;

    gap: ${t("xl")};
    padding-top: ${t("xl")};
    padding-bottom: ${t("xl")};
    
    padding-left: ${t("md")};
    padding-right: ${t("md")};
  };
  
  ${(o) => w[o.$position]};
`, L = (o) => {
  const {
    children: r,
    className: e,
    position: n = "bottom-left"
  } = o;
  return /* @__PURE__ */ i.jsx(
    F,
    {
      $position: n,
      className: e,
      children: r
    }
  );
}, M = 8e3, ei = (o) => {
  const {
    children: r,
    className: e,
    position: n = "bottom-left",
    autoHideDuration: d = M
  } = o, {
    enqueueNotification: m,
    removeAllNotifications: c,
    notificationList: s
  } = P({
    autoHideDuration: d
  });
  return /* @__PURE__ */ i.jsxs(
    E.Provider,
    {
      value: {
        enqueueNotification: m,
        removeAllNotifications: c
      },
      children: [
        r,
        s.length > 0 && /* @__PURE__ */ i.jsx(
          L,
          {
            className: e,
            position: n,
            children: s.map((l) => /* @__PURE__ */ i.jsx(
              R,
              {
                ...l,
                position: n
              },
              l.id
            ))
          }
        )
      ]
    }
  );
};
export {
  ei as default,
  si as useNotification
};
