import { css as d } from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-atf5CkC7.js";
import { getRadii as i, getSpacings as t, getPaletteColors as o, getPaletteBase as g, getPaletteForeground as h, getEffects as s } from "../Theme/utils.es.js";
import "react";
import "../../jsx-runtime-B6kdoens.js";
import "../../CssBaseline-XoOio97s.js";
const b = {
  sm: d`
    width: 32px;
    height: 32px;
    
    > svg {
      width: 16px;
      height: 16px;
    }
  `,
  md: d`
    width: 40px;
    height: 40px;
    
    > svg {
      width: 20px;
      height: 20px;
    }
  `,
  lg: d`
    width: 48px;
    height: 48px;
    
    > svg {
      width: 24px;
      height: 24px;
    }
  `,
  xl: d`
    width: 56px;
    height: 56px;
    
    > svg {
      width: 28px;
      height: 28px;
    }
  `,
  xxl: d`
    width: 72px;
    height: 72px;
    
    > svg {
      width: 32px;
      height: 32px;
    }
  `
}, e = {
  sm: d`
    border-radius: ${i("sm")};
  `,
  md: d`
    border-radius: ${i("md")};
  `,
  lg: d`
    border-radius: ${i("lg")};
  `,
  xl: d`
    border-radius: ${i("lg")};
  `,
  xxl: d`
    border-radius: ${i("2xl")};
  `
}, n = {
  light: d`
    padding: ${t("md")};
    border-radius: ${i("full")};
    color: ${(r) => o(r.$color)(r)[600]};
    background-color: ${(r) => o(r.$color)(r)[100]};
  `,
  dark: d`
    padding: ${t("md")};
    color: ${g("white")};
    background-color: ${(r) => o(r.$color)(r)[600]};
    
    ${(r) => e[r.$size]};
  `,
  glass: d`
    z-index: 1;
    border: 1px solid rgba(255, 255, 255, 0.60);
    background: rgba(255, 255, 255, 0.60);
    backdrop-filter: blur(10.285714149475098px);
    
    padding: ${t("md")};
    color: ${h("white")};
    
    ${(r) => e[r.$size]};
  `,
  modern: d`
    padding: ${t("md")};
    color: ${(r) => o(r.$color)(r)[600]};
    border: 1px solid ${o("gray.200")};
    box-shadow: ${s("shadows.xs")};
    
    ${(r) => e[r.$size]};
  `
};
export {
  e as borderRadiusStyles,
  b as sizeStyles,
  n as variantStyles
};
