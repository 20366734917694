import { j as e } from "../../jsx-runtime-B6kdoens.js";
import "../../styles-DaQVIHg4.js";
import { T as s } from "../../Typography-Cc9k4gPj.js";
import { B as r } from "../../Button-Cy1O58in.js";
import { I as m } from "../../CheckIcon-Dl7Gf-OR.js";
import "react";
import "../Icon/countryVariants.es.js";
import l from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-atf5CkC7.js";
import { getSpacings as a } from "../Theme/utils.es.js";
import "../../CssBaseline-XoOio97s.js";
import "../../ErrorFeedbackContainer-bFrqdqf0.js";
import { P as g, S as d } from "../../SomethingWentWrongFeedback-CetBirKr.js";
const x = l.div`
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  height: 100vh;
`, h = l.div`
  flex: 1 0 0;
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  gap: ${a("6xl")} ${a("6xl")};
`, p = l.div`
  display: flex;
  align-items: flex-start;
  gap: ${a("lg")};
`, c = (o) => {
  const {
    className: i,
    actions: t,
    feedback: n
  } = o;
  return /* @__PURE__ */ e.jsx(x, { className: i, children: /* @__PURE__ */ e.jsxs(h, { children: [
    n,
    /* @__PURE__ */ e.jsx(p, { children: t })
  ] }) });
}, G = (o) => {
  const {
    className: i,
    onGoBackClick: t,
    onGotoLoginClick: n
  } = o;
  return /* @__PURE__ */ e.jsx(
    c,
    {
      className: i,
      feedback: /* @__PURE__ */ e.jsx(
        g,
        {
          message: "The page you are looking for doesn't exist or has been moved."
        }
      ),
      actions: /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
        /* @__PURE__ */ e.jsx(
          r,
          {
            size: "2xl",
            startAdornment: /* @__PURE__ */ e.jsx(m, { variant: "arrow-left" }),
            variant: "outlined",
            color: "gray",
            onClick: t,
            children: /* @__PURE__ */ e.jsx(s, { size: "lg", weight: "semibold", color: "secondary", children: "Go back" })
          }
        ),
        /* @__PURE__ */ e.jsx(
          r,
          {
            size: "2xl",
            onClick: n,
            children: /* @__PURE__ */ e.jsx(s, { size: "lg", weight: "semibold", color: "white", children: "Go to log in" })
          }
        )
      ] })
    }
  );
}, S = (o) => {
  const {
    className: i,
    onRefreshClick: t
  } = o;
  return /* @__PURE__ */ e.jsx(
    c,
    {
      className: i,
      feedback: /* @__PURE__ */ e.jsx(
        d,
        {
          message: "We are working on fixing the problem. Please try again."
        }
      ),
      actions: /* @__PURE__ */ e.jsx(
        r,
        {
          size: "2xl",
          onClick: t,
          children: /* @__PURE__ */ e.jsx(s, { size: "lg", weight: "semibold", color: "white", children: "Refresh page" })
        }
      )
    }
  );
};
export {
  G as PageNotFound,
  S as SomethingWentWrong
};
