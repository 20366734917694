import { css as o } from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-atf5CkC7.js";
import { getPaletteColors as p, getPaletteForeground as i, getPaletteBorder as r, getSpacings as e } from "../Theme/utils.es.js";
import "react";
import "../../jsx-runtime-B6kdoens.js";
import "../../CssBaseline-XoOio97s.js";
const g = {
  primary: o`
    padding: 15px 7px 7px 7px;
    
    > svg {
      width: 18px;
      height: 18px;
    }
    
    color: ${(t) => p(t.$color)(t)[600]};
  `,
  gray: o`
    padding: 16px 8px 8px 8px;
    
    > svg {
      width: 16px;
      height: 16px;
    }
    
    color: ${i("secondary.default")};
  `,
  solid: o`
    padding: 16px 8px 8px 8px;
    
    > svg {
      width: 16px;
      height: 16px;
    }
    
    color: ${i("white")};
  `
}, m = {
  primary: o`
    color: ${r("primary")};
  `,
  gray: o``,
  solid: o`
    color: ${(t) => p(t.$color)(t)[600]};
  `
}, h = {
  primary: o`
    border-radius: 2px;
    position: absolute;
    z-index: 1;
    left: -7px;
    bottom: 8px;
    
    padding: ${e("xxs")} 3px;
    color: ${i("white")};
    background-color: ${(t) => p(t.$color)(t)[600]};
  `,
  gray: o`
    position: absolute;
    z-index: 1;
    width: 100%;
    justify-content: center;
    padding-top: 23px;
    padding-bottom: 6px;
    
    color: ${i("secondary.default")};
  `,
  solid: o`
    position: absolute;
    z-index: 1;
    width: 100%;
    justify-content: center;
    padding-top: 23px;
    padding-bottom: 6px;
    
    color: ${i("white")};
  `
};
export {
  h as extensionVariantStyles,
  g as iconVariantStyles,
  m as pageIconVariantStyles
};
