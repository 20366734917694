import { Menu as e } from "@headlessui/react";
import r from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-atf5CkC7.js";
import { getZIndexes as d, getRadii as t, getEffects as i, getPaletteBorder as m, getPaletteBackground as s } from "../Theme/utils.es.js";
import "react";
import "../../jsx-runtime-B6kdoens.js";
import "../../CssBaseline-XoOio97s.js";
const $ = r(e.Items)`
  padding: 0;
  overflow: hidden;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  flex-direction: column;

  z-index: ${d("modal")};
  border-radius: ${t("md")};
  box-shadow: ${i("shadows.lg")};
  border-color: ${m("secondary")};
  background: ${s("primary.default")};
  display: ${(o) => o.$isHidden ? "none" : "flex"};
  width: ${(o) => o.$isFullWidth ? "100%" : "240px"};
`;
export {
  $ as Items
};
