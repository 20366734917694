import { j as r } from "./jsx-runtime-B6kdoens.js";
import { forwardRef as h } from "react";
import l from "styled-components";
import "./components/Theme/themes.es.js";
import "./palettes-atf5CkC7.js";
import { getRadii as u, getPaletteForeground as x } from "./components/Theme/utils.es.js";
import "./CssBaseline-XoOio97s.js";
import { sizeStyles as y, variantStyles as $, iconSizeStyles as g } from "./components/Button/styles.es.js";
import "./styles-DaQVIHg4.js";
import { T as z } from "./Typography-Cc9k4gPj.js";
const S = {
  xs: "xs",
  sm: "sm",
  md: "sm",
  lg: "md",
  xl: "md",
  "2xl": "lg"
}, j = l.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  border-radius: ${u("full")};

  ${(t) => y[t.$size]};
  ${(t) => $[t.$variant]};

  &:disabled {
    cursor: not-allowed;

    color: ${x("disabled.default")};
  }
`, s = l.div`
  display: inline-flex;

  ${(t) => g[t.$size]}

  > svg {
    width: inherit;
    height: inherit;
  }
`, _ = h(
  function(d, m) {
    const {
      color: a = "brand",
      size: i = "md",
      variant: c = "contained",
      children: o,
      startAdornment: e,
      endAdornment: n,
      ...p
    } = d, f = S[i];
    return /* @__PURE__ */ r.jsxs(
      j,
      {
        ref: m,
        $color: a,
        $size: i,
        $variant: c,
        ...p,
        children: [
          e && /* @__PURE__ */ r.jsx(s, { $size: i, children: e }),
          typeof o == "string" ? /* @__PURE__ */ r.jsx(
            z,
            {
              color: "inherit",
              weight: "semibold",
              size: f,
              children: o
            }
          ) : o,
          n && /* @__PURE__ */ r.jsx(s, { $size: i, children: n })
        ]
      }
    );
  }
);
export {
  _ as B
};
