import { j as e } from "./jsx-runtime-B6kdoens.js";
import p from "styled-components";
import { c as w } from "./clsx.m-BMVuQnLY.js";
import { i as M } from "./isExhaustive-RFXKPwPh.js";
import { A as X } from "./AvatarLabelGroup-BrtJN_oH.js";
import "./styles-DaQVIHg4.js";
import { T as j } from "./Typography-Cc9k4gPj.js";
import { getPaletteForeground as D, getSpacings as I, getRadii as P, getPaletteBackground as b, getPaletteBorder as T, getPaletteBase as O } from "./components/Theme/utils.es.js";
import { createPortal as W } from "react-dom";
import { Menu as g } from "@headlessui/react";
import { A as Y } from "./Avatar-zTVjKUHH.js";
import "./components/Theme/themes.es.js";
import "./palettes-atf5CkC7.js";
import { useRef as Z, useMemo as _ } from "react";
import "./CssBaseline-XoOio97s.js";
import { I as S } from "./CheckIcon-Dl7Gf-OR.js";
import "./components/Icon/countryVariants.es.js";
import { Items as H } from "./components/Dropdown/style.es.js";
import { B as ee } from "./Button-Cy1O58in.js";
import { u as te } from "./useDisclose-DpzoV9h_.js";
import { u as q } from "./useAppFloating-1R8z_SOW.js";
import { I as re } from "./IconButton-CyhPHivf.js";
const ne = p.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: 100%;
`, oe = p.div`
  flex: 1;
  text-align: left;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`, z = p.div`
  display: inline-flex;

  color: ${D("quaternary.default")};
`, L = (r) => {
  const {
    text: n,
    shortcut: t,
    leftIcon: o,
    rightIcon: s,
    className: c
  } = r, i = {
    width: 16,
    height: 16
  };
  return /* @__PURE__ */ e.jsxs(ne, { className: c, children: [
    o && /* @__PURE__ */ e.jsx(z, { children: /* @__PURE__ */ e.jsx(
      S,
      {
        ...i,
        ...o
      }
    ) }),
    /* @__PURE__ */ e.jsxs(oe, { children: [
      /* @__PURE__ */ e.jsx(j, { variant: "text", size: "sm", weight: "medium", as: "span", className: "text", children: n }),
      t && /* @__PURE__ */ e.jsx(j, { variant: "text", size: "xs", weight: "regular", as: "span", color: "tertiary", children: t })
    ] }),
    s && /* @__PURE__ */ e.jsx(z, { children: /* @__PURE__ */ e.jsx(
      S,
      {
        ...i,
        ...s
      }
    ) })
  ] });
}, se = p.button`
  margin: ${I("xxs")} ${I("sm")};

  flex: 1;

  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: ${P("sm")};

  padding: 9px 10px;

  border: none;

  color: ${D("quaternary.default")};

  background-color: ${b("primary.default")};

  overflow: hidden;

  outline: none;

  background-color: ${(r) => r.$isActive ? b("primary.hover") : "inherit"};

  &:not(.disabled):hover {
    cursor: pointer;
    background-color: ${b("primary.hover")};
  }
`, A = (r) => {
  const {
    selected: n,
    disabled: t,
    onClick: o,
    className: s,
    ...c
  } = r, i = w(
    {
      selected: n,
      disabled: t
    },
    s
  );
  return /* @__PURE__ */ e.jsx(g.Item, { disabled: t, children: ({ active: f }) => /* @__PURE__ */ e.jsx(
    se,
    {
      onClick: o,
      $isActive: f,
      className: i,
      children: /* @__PURE__ */ e.jsx(L, { ...c })
    }
  ) });
}, ie = p.div`
  z-index: 10;
  
  display: flex;
  flex-direction: column;
  
  padding: 4px 0;
    
  &:not(:first-child) {
    border-top: 1px solid ${T("secondary")};
  }
`, ae = (r) => {
  const {
    className: n,
    children: t,
    ...o
  } = r;
  return /* @__PURE__ */ e.jsx(ie, { ...o, className: n, children: t });
}, V = p.header`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  
  padding: 12px 16px;

  border-bottom: 1px solid ${T("secondary")};
`, Oe = (r) => {
  switch (r.variant) {
    case "avatar": {
      const {
        variant: n,
        avatar: {
          size: t,
          ...o
        },
        className: s,
        ...c
      } = r;
      return /* @__PURE__ */ e.jsx(V, { ...c, className: w(`variant-${n}`, s), children: /* @__PURE__ */ e.jsx(X, { ...o, size: t ?? "md" }) });
    }
    case "text": {
      const {
        variant: n,
        text: t,
        className: o,
        ...s
      } = r;
      return /* @__PURE__ */ e.jsx(V, { ...s, className: w(`variant-${n}`, o), children: /* @__PURE__ */ e.jsx(j, { variant: "text", size: "sm", weight: "semibold", as: "span", children: t }) });
    }
    default:
      M(r);
  }
}, le = (r) => {
  const {
    currentValue: n,
    selectedValue: t,
    isMultiple: o
  } = r;
  if (!o)
    return t;
  const s = n.split(",").filter((i) => !!i);
  return s.find((i) => i === t) ? [
    ...s
  ].filter((i) => i !== t).join(",") : [
    ...s,
    t
  ].join(",");
}, ce = p(ae)`
  &:not(:first-child) {
    border: none;
  }
  .selected {
    svg {
      color: ${D("brand.primary.default")};
    }
  }
`, de = p.input`
  display: none;
`, ue = (r) => {
  const {
    label: n,
    value: t,
    options: o,
    className: s,
    placement: c,
    renderOption: i,
    onChange: f,
    inputRef: v,
    isMultiple: h,
    ...B
  } = r, {
    isOpen: N,
    onClose: k,
    onOpen: C
  } = te(!1), {
    refs: y,
    floatingStyles: u
  } = q({
    floatingOptions: {
      placement: c,
      open: !1
    }
  }), x = Z(null), $ = _(() => {
    if (h) {
      const a = [
        ...new Set(t.split(","))
      ];
      if (a.length === 1) {
        const [m] = a, d = o.find((F) => F.value === m);
        return d ? `: ${d.label}` : "";
      }
      if (a.length > 1)
        return ` (${a.length})`;
    }
    const l = o.find((a) => a.value === t);
    return l ? `: ${l.label}` : "";
  }, [
    t,
    o
  ]), G = o.length === 0, J = typeof i < "u", K = (l) => h ? t.split(",").findIndex((m) => m === l) !== -1 : l === t, R = (l, a) => {
    var d;
    const m = (d = Object.getOwnPropertyDescriptor(
      HTMLInputElement.prototype,
      "value"
    )) == null ? void 0 : d.set;
    if (x.current && m) {
      const F = le({
        currentValue: t,
        selectedValue: a,
        isMultiple: h
      });
      m.call(x.current, F);
      const U = new Event("input", {
        bubbles: !0
      });
      x.current.dispatchEvent(U);
    }
    h && l.preventDefault();
  }, Q = () => {
    N ? k() : C();
  };
  return /* @__PURE__ */ e.jsxs(g, { as: ce, children: [
    /* @__PURE__ */ e.jsx(
      g.Button,
      {
        as: ee,
        className: s,
        ref: y.setReference,
        onClick: Q,
        ...B,
        children: typeof n == "string" ? /* @__PURE__ */ e.jsxs(j, { color: "inherit", size: "sm", weight: "semibold", children: [
          n,
          $
        ] }) : /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
          n,
          /* @__PURE__ */ e.jsx(j, { color: "inherit", size: "sm", weight: "semibold", children: $ })
        ] })
      }
    ),
    /* @__PURE__ */ e.jsx(
      de,
      {
        ref: (l) => {
          typeof v == "function" ? v(l) : v && (v.current = l), x.current = l;
        },
        value: t,
        onChange: f
      }
    ),
    W(
      /* @__PURE__ */ e.jsx(H, { style: u, ref: y.setFloating, children: G ? /* @__PURE__ */ e.jsx(A, { disabled: !0, text: "No data" }) : /* @__PURE__ */ e.jsx(e.Fragment, { children: o.map(({ label: l, value: a }) => {
        const m = K(a);
        return J ? /* @__PURE__ */ e.jsx(
          g.Item,
          {
            as: "div",
            onClick: (d) => R(d, a),
            children: ({ active: d }) => i({ label: l, value: a, isSelected: m, isActive: d })
          },
          a
        ) : /* @__PURE__ */ e.jsx(
          A,
          {
            text: l,
            selected: m,
            onClick: (d) => R(d, a),
            rightIcon: m ? { variant: "check", color: "red" } : void 0
          },
          a
        );
      }) }) }),
      document.body
    )
  ] });
}, E = p(g.Button)`
  &:not(.disabled) {
    cursor: pointer;
  }

  padding: 0;
  border-style: none;
  background: ${b("primary.default")};

  &.variant-avatar {
    color: ${O("black")};

    &:hover {
      color: ${O("black")};
    }

    outline: none;
  }

  &.variant-select {
    padding: ${I("md")} ${I("lg")};
    border-radius: ${P("sm")};

    &:hover {
      background: ${b("primary.hover")};
    }
  }

  &.variant-menu {
    border-radius: ${P("full")};
    
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    
    &:hover {
      box-shadow: 0 0 0 4px rgba(152, 162, 179, 0.14);
    }
  }

  width: ${(r) => r.$isFullWidth ? "100%" : ""};
`, ze = (r) => {
  const {
    variant: n,
    disabled: t,
    children: o,
    className: s,
    fullWidth: c,
    placement: i = "bottom-end"
  } = r, {
    refs: f,
    floatingStyles: v
  } = q({
    floatingOptions: {
      placement: i
    }
  }), h = w(`variant-${n}`, s), B = (u) => {
    const {
      size: x = "md",
      ...$
    } = u.avatarProps;
    return /* @__PURE__ */ e.jsx(
      E,
      {
        ref: f.setReference,
        className: h,
        disabled: t,
        children: /* @__PURE__ */ e.jsx(Y, { size: x, ...$ })
      }
    );
  }, N = (u) => /* @__PURE__ */ e.jsx(
    E,
    {
      className: h,
      ref: f.setReference,
      $isFullWidth: c,
      disabled: t,
      children: /* @__PURE__ */ e.jsx(L, { ...u.selectProps })
    }
  ), k = (u) => /* @__PURE__ */ e.jsx(
    ue,
    {
      placement: i,
      ...u.filterSelectProps
    }
  ), C = (u) => {
    const x = {
      variant: "outlined",
      color: "gray",
      ...u.iconButtonProps
    };
    return /* @__PURE__ */ e.jsx(
      g.Button,
      {
        ...x,
        ref: f.setReference,
        as: re,
        children: /* @__PURE__ */ e.jsx(S, { variant: u.iconVariant })
      }
    );
  }, y = () => {
    switch (n) {
      case "avatar":
        return B(r);
      case "select":
        return N(r);
      case "filter":
        return k(r);
      case "icon":
        return C(r);
      default:
        M(n);
    }
  };
  return /* @__PURE__ */ e.jsxs(g, { children: [
    y(),
    n === "filter" ? void 0 : /* @__PURE__ */ e.jsx(e.Fragment, { children: W(
      /* @__PURE__ */ e.jsx(
        H,
        {
          ref: f.setFloating,
          style: v,
          $isFullWidth: c,
          children: o
        }
      ),
      document.body
    ) })
  ] });
};
export {
  A as D,
  ae as a,
  Oe as b,
  ze as c,
  ue as d,
  L as e
};
