import { j as e } from "./jsx-runtime-B6kdoens.js";
import n from "styled-components";
import { I as l } from "./CheckIcon-Dl7Gf-OR.js";
import { useMemo as p, Suspense as z } from "react";
import "./components/Icon/countryVariants.es.js";
import { sizeStyles as c, variantStyles as f, borderRadiusStyles as u } from "./components/FeaturedIcon/styles.es.js";
import "./components/Theme/themes.es.js";
import "./palettes-atf5CkC7.js";
import { getPaletteColors as $, getRadii as x } from "./components/Theme/utils.es.js";
import "./CssBaseline-XoOio97s.js";
import { sizeStyles as v, outerSizeStyles as I, innerSizeStyles as b, iconSizeStyles as g } from "./components/FeaturedIcon/outlinedStyles.es.js";
import { iconVariantStyles as j, pageIconVariantStyles as S, extensionVariantStyles as h } from "./components/FeaturedIcon/fileIconStyles.es.js";
import { FILE_ICON_TYPE_COLOR_MAPPER as P, FILE_ICON_EXTENSION_COLOR_MAPPER as d, VARIANT_PAGE_MAPPER as E, FILE_TYPE_ICON_MAPPER as C } from "./components/FeaturedIcon/constants.es.js";
import { getMIMEExtension as _ } from "./components/FeaturedIcon/utils.es.js";
const m = n.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  
  ${(i) => v[i.$size]};
  
  color: ${(i) => $(i.$color)(i)[600]};
`, y = n(m)`
  position: absolute;
  opacity: 0.1;
  
  border: 2px solid ${(i) => $(i.$color)(i)[600]};
  border-radius: ${x("full")};
  ${(i) => I[i.$size]};
`, O = n(y)`
  opacity: 0.3;
  
  ${(i) => b[i.$size]};
`, R = n(l)`
  ${(i) => g[i.$size]};
`, N = (i) => {
  const {
    className: t,
    color: s = "brand",
    size: o = "md",
    iconProps: r
  } = i;
  return /* @__PURE__ */ e.jsxs(
    m,
    {
      className: t,
      $size: o,
      $color: s,
      children: [
        /* @__PURE__ */ e.jsx(
          y,
          {
            $size: o,
            $color: s
          }
        ),
        /* @__PURE__ */ e.jsx(
          O,
          {
            $size: o,
            $color: s
          }
        ),
        /* @__PURE__ */ e.jsx(
          R,
          {
            $size: o,
            ...r
          }
        )
      ]
    }
  );
}, A = n.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  
  ${(i) => c[i.$size]};
`, F = n.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  
  ${(i) => f[i.$variant]};
  ${(i) => c[i.$size]};
`, M = n.div`
  position: absolute;
  transform: rotate(15deg);
  left: 20%;
  bottom: 20%;
  
  border-radius: ${x("md")};
  background-color: ${(i) => $(i.$color)(i)[600]};
  
  ${(i) => c[i.$size]};
  ${(i) => u[i.$size]};
`, ii = (i) => {
  const {
    variant: t,
    className: s,
    size: o = "md",
    color: r = "brand",
    iconProps: a
  } = i;
  return t === "outlined" ? /* @__PURE__ */ e.jsx(
    N,
    {
      className: s,
      size: o,
      color: r,
      iconProps: a
    }
  ) : /* @__PURE__ */ e.jsxs(
    A,
    {
      $size: o,
      children: [
        /* @__PURE__ */ e.jsx(
          F,
          {
            $variant: t,
            $color: r,
            $size: o,
            className: s,
            children: /* @__PURE__ */ e.jsx(l, { ...a })
          }
        ),
        t === "glass" && /* @__PURE__ */ e.jsx(
          M,
          {
            $size: o,
            $color: r
          }
        )
      ]
    }
  );
}, T = n.div`
  display: inline-flex;
  position: relative;
  width: 32px;
  height: 40px;
`, L = n.div`
  display: inline-flex;
  position: absolute;
  z-index: 1;
  
  ${(i) => j[i.$variant]};
`, w = n.div`
  
  > svg {
    width: 32px;
    height: 40px;
  }
  
  ${(i) => S[i.$variant]};
`, V = n.span`
  display: inline-flex;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  
  ${(i) => h[i.$variant]};
`, ei = (i) => {
  const {
    variant: t = "primary",
    className: s
  } = i, o = "extension" in i ? i.extension : "mimeType" in i ? _(i.mimeType) : void 0, r = p(() => "type" in i ? P[i.type] : o ? d[o] : "gray", [i]), a = E[t];
  return /* @__PURE__ */ e.jsx(z, { children: /* @__PURE__ */ e.jsxs(
    T,
    {
      $color: r,
      $variant: t,
      className: s,
      children: [
        /* @__PURE__ */ e.jsx(
          w,
          {
            $color: r,
            $variant: t,
            children: /* @__PURE__ */ e.jsx(a, {})
          }
        ),
        "type" in i && /* @__PURE__ */ e.jsx(
          L,
          {
            $color: r,
            $variant: t,
            children: /* @__PURE__ */ e.jsx(
              l,
              {
                variant: C[i.type]
              }
            )
          }
        ),
        o && d[o] && /* @__PURE__ */ e.jsx(
          V,
          {
            $color: r,
            $variant: t,
            children: o
          }
        )
      ]
    }
  ) });
};
export {
  ii as F,
  N as O,
  ei as a
};
