import { j as i } from "../../jsx-runtime-B6kdoens.js";
import r from "lottie-react";
import s, { css as e } from "styled-components";
import "../../styles-DaQVIHg4.js";
import { T as k } from "../../Typography-Cc9k4gPj.js";
import "../../components/Theme/themes.es.js";
import "../../palettes-atf5CkC7.js";
import { getSpacings as n } from "../../components/Theme/utils.es.js";
import "react";
import "../../CssBaseline-XoOio97s.js";
const m = "5.8.1", p = 29.9700012207031, y = 0, c = 49.0000019958109, l = 64, d = 64, h = "lottie", f = 0, u = [], v = [
  {
    ddd: 0,
    ind: 1,
    ty: 4,
    nm: "core",
    sr: 1,
    ks: {
      o: {
        a: 0,
        k: 100,
        ix: 11
      },
      r: {
        a: 0,
        k: 0,
        ix: 10
      },
      p: {
        a: 0,
        k: [
          32,
          32,
          0
        ],
        ix: 2,
        l: 2
      },
      a: {
        a: 0,
        k: [
          -1.982,
          -12.035,
          0
        ],
        ix: 1,
        l: 2
      },
      s: {
        a: 0,
        k: [
          64,
          64,
          100
        ],
        ix: 6,
        l: 2
      }
    },
    ao: 0,
    hasMask: !0,
    masksProperties: [
      {
        inv: !1,
        mode: "a",
        pt: {
          a: 1,
          k: [
            {
              i: {
                x: 0.729,
                y: 1
              },
              o: {
                x: 0.721,
                y: 0
              },
              t: 0,
              s: [
                {
                  i: [
                    [
                      0,
                      -15.185
                    ],
                    [
                      0,
                      -20.146
                    ],
                    [
                      0,
                      24.218
                    ],
                    [
                      25.032,
                      0
                    ]
                  ],
                  o: [
                    [
                      0,
                      15.37
                    ],
                    [
                      24.517,
                      0
                    ],
                    [
                      0,
                      -24.218
                    ],
                    [
                      0,
                      18.294
                    ]
                  ],
                  v: [
                    [
                      0.426,
                      0.5
                    ],
                    [
                      0.425,
                      44.391
                    ],
                    [
                      44.338,
                      0.436
                    ],
                    [
                      0.425,
                      -43.391
                    ]
                  ],
                  c: !0
                }
              ]
            },
            {
              t: 12,
              s: [
                {
                  i: [
                    [
                      0.037,
                      -23.519
                    ],
                    [
                      23.964,
                      0.039
                    ],
                    [
                      -0.049,
                      23.768
                    ],
                    [
                      -23.999,
                      -0.039
                    ]
                  ],
                  o: [
                    [
                      0.037,
                      23.704
                    ],
                    [
                      -23.999,
                      0.039
                    ],
                    [
                      0.048,
                      -23.454
                    ],
                    [
                      23.964,
                      -0.039
                    ]
                  ],
                  v: [
                    [
                      41.781,
                      -11.907
                    ],
                    [
                      -2.055,
                      31.943
                    ],
                    [
                      -45.916,
                      -11.972
                    ],
                    [
                      -2.055,
                      -55.757
                    ]
                  ],
                  c: !0
                }
              ],
              h: 1
            },
            {
              i: {
                x: 0,
                y: 1
              },
              o: {
                x: 0.577,
                y: 0
              },
              t: 26,
              s: [
                {
                  i: [
                    [
                      0.037,
                      -23.519
                    ],
                    [
                      23.964,
                      0.039
                    ],
                    [
                      -0.049,
                      23.768
                    ],
                    [
                      -23.999,
                      -0.039
                    ]
                  ],
                  o: [
                    [
                      0.037,
                      23.704
                    ],
                    [
                      -23.999,
                      0.039
                    ],
                    [
                      0.048,
                      -23.454
                    ],
                    [
                      23.964,
                      -0.039
                    ]
                  ],
                  v: [
                    [
                      41.781,
                      -11.907
                    ],
                    [
                      -2.055,
                      31.943
                    ],
                    [
                      -45.916,
                      -11.972
                    ],
                    [
                      -2.055,
                      -55.757
                    ]
                  ],
                  c: !0
                }
              ]
            },
            {
              t: 49.0000019958109,
              s: [
                {
                  i: [
                    [
                      0,
                      -15.37
                    ],
                    [
                      0,
                      -20.187
                    ],
                    [
                      1e-3,
                      23.704
                    ],
                    [
                      -23.889,
                      2e-3
                    ]
                  ],
                  o: [
                    [
                      0,
                      15.556
                    ],
                    [
                      -23.889,
                      -2e-3
                    ],
                    [
                      -1e-3,
                      -23.519
                    ],
                    [
                      0,
                      20.372
                    ]
                  ],
                  v: [
                    [
                      -1.981,
                      -12.093
                    ],
                    [
                      -1.983,
                      31.798
                    ],
                    [
                      -45.781,
                      -12.093
                    ],
                    [
                      -1.983,
                      -55.983
                    ]
                  ],
                  c: !0
                }
              ]
            }
          ],
          ix: 1
        },
        o: {
          a: 0,
          k: 100,
          ix: 3
        },
        x: {
          a: 0,
          k: 0,
          ix: 4
        },
        nm: "Mask 1"
      }
    ],
    shapes: [
      {
        ty: "gr",
        it: [
          {
            ind: 0,
            ty: "sh",
            ix: 1,
            ks: {
              a: 0,
              k: {
                i: [
                  [
                    0,
                    -11.598
                  ],
                  [
                    11.598,
                    0
                  ],
                  [
                    0,
                    11.598
                  ],
                  [
                    -11.598,
                    0
                  ]
                ],
                o: [
                  [
                    0,
                    11.598
                  ],
                  [
                    -11.598,
                    0
                  ],
                  [
                    0,
                    -11.598
                  ],
                  [
                    11.598,
                    0
                  ]
                ],
                v: [
                  [
                    19.018,
                    -12.035
                  ],
                  [
                    -1.982,
                    8.965
                  ],
                  [
                    -22.982,
                    -12.035
                  ],
                  [
                    -1.982,
                    -33.035
                  ]
                ],
                c: !0
              },
              ix: 2
            },
            nm: "Path 1",
            mn: "ADBE Vector Shape - Group",
            hd: !1
          },
          {
            ty: "tr",
            p: {
              a: 0,
              k: [
                -1.985,
                -12.043
              ],
              ix: 2
            },
            a: {
              a: 0,
              k: [
                -1.977,
                -11.993
              ],
              ix: 1
            },
            s: {
              a: 1,
              k: [
                {
                  i: {
                    x: [
                      0.833,
                      0.833
                    ],
                    y: [
                      0.636,
                      0.636
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167
                    ],
                    y: [
                      0.167,
                      0.167
                    ]
                  },
                  t: 18,
                  s: [
                    0,
                    0
                  ]
                },
                {
                  i: {
                    x: [
                      0.833,
                      0.833
                    ],
                    y: [
                      0.758,
                      0.758
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167
                    ],
                    y: [
                      0.108,
                      0.108
                    ]
                  },
                  t: 19,
                  s: [
                    3.158,
                    3.158
                  ]
                },
                {
                  i: {
                    x: [
                      0.833,
                      0.833
                    ],
                    y: [
                      0.786,
                      0.786
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167
                    ],
                    y: [
                      0.127,
                      0.127
                    ]
                  },
                  t: 20,
                  s: [
                    13.788,
                    13.788
                  ]
                },
                {
                  i: {
                    x: [
                      0.833,
                      0.833
                    ],
                    y: [
                      0.828,
                      0.828
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167
                    ],
                    y: [
                      0.136,
                      0.136
                    ]
                  },
                  t: 21,
                  s: [
                    34.083,
                    34.083
                  ]
                },
                {
                  i: {
                    x: [
                      0.833,
                      0.833
                    ],
                    y: [
                      0.901,
                      0.901
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167
                    ],
                    y: [
                      0.161,
                      0.161
                    ]
                  },
                  t: 22,
                  s: [
                    65.957,
                    65.957
                  ]
                },
                {
                  i: {
                    x: [
                      0.833,
                      0.833
                    ],
                    y: [
                      0.891,
                      0.891
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167
                    ],
                    y: [
                      0.53,
                      0.53
                    ]
                  },
                  t: 23,
                  s: [
                    100,
                    100
                  ]
                },
                {
                  i: {
                    x: [
                      0.833,
                      0.833
                    ],
                    y: [
                      0.928,
                      0.928
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167
                    ],
                    y: [
                      0.351,
                      0.351
                    ]
                  },
                  t: 24,
                  s: [
                    106.352,
                    106.352
                  ]
                },
                {
                  i: {
                    x: [
                      0.833,
                      0.833
                    ],
                    y: [
                      0.528,
                      0.528
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167
                    ],
                    y: [
                      -0.539,
                      -0.539
                    ]
                  },
                  t: 25,
                  s: [
                    108.326,
                    108.326
                  ]
                },
                {
                  i: {
                    x: [
                      0.833,
                      0.833
                    ],
                    y: [
                      0.815,
                      0.815
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167
                    ],
                    y: [
                      0.101,
                      0.101
                    ]
                  },
                  t: 26,
                  s: [
                    108.061,
                    108.061
                  ]
                },
                {
                  i: {
                    x: [
                      0.833,
                      0.833
                    ],
                    y: [
                      0.838,
                      0.838
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167
                    ],
                    y: [
                      0.152,
                      0.152
                    ]
                  },
                  t: 27,
                  s: [
                    106.828,
                    106.828
                  ]
                },
                {
                  i: {
                    x: [
                      0.833,
                      0.833
                    ],
                    y: [
                      0.847,
                      0.847
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167
                    ],
                    y: [
                      0.172,
                      0.172
                    ]
                  },
                  t: 28,
                  s: [
                    105.331,
                    105.331
                  ]
                },
                {
                  i: {
                    x: [
                      0.833,
                      0.833
                    ],
                    y: [
                      0.852,
                      0.852
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167
                    ],
                    y: [
                      0.183,
                      0.183
                    ]
                  },
                  t: 29,
                  s: [
                    103.922,
                    103.922
                  ]
                },
                {
                  i: {
                    x: [
                      0.833,
                      0.833
                    ],
                    y: [
                      0.856,
                      0.856
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167
                    ],
                    y: [
                      0.191,
                      0.191
                    ]
                  },
                  t: 30,
                  s: [
                    102.747,
                    102.747
                  ]
                },
                {
                  i: {
                    x: [
                      0.833,
                      0.833
                    ],
                    y: [
                      0.859,
                      0.859
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167
                    ],
                    y: [
                      0.198,
                      0.198
                    ]
                  },
                  t: 31,
                  s: [
                    101.84,
                    101.84
                  ]
                },
                {
                  i: {
                    x: [
                      0.833,
                      0.833
                    ],
                    y: [
                      0.861,
                      0.861
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167
                    ],
                    y: [
                      0.203,
                      0.203
                    ]
                  },
                  t: 32,
                  s: [
                    101.179,
                    101.179
                  ]
                },
                {
                  i: {
                    x: [
                      0.833,
                      0.833
                    ],
                    y: [
                      0.864,
                      0.864
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167
                    ],
                    y: [
                      0.209,
                      0.209
                    ]
                  },
                  t: 33,
                  s: [
                    100.719,
                    100.719
                  ]
                },
                {
                  i: {
                    x: [
                      0.833,
                      0.833
                    ],
                    y: [
                      0.866,
                      0.866
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167
                    ],
                    y: [
                      0.214,
                      0.214
                    ]
                  },
                  t: 34,
                  s: [
                    100.413,
                    100.413
                  ]
                },
                {
                  i: {
                    x: [
                      0.833,
                      0.833
                    ],
                    y: [
                      0.869,
                      0.869
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167
                    ],
                    y: [
                      0.221,
                      0.221
                    ]
                  },
                  t: 35,
                  s: [
                    100.219,
                    100.219
                  ]
                },
                {
                  i: {
                    x: [
                      0.833,
                      0.833
                    ],
                    y: [
                      0.833,
                      0.833
                    ]
                  },
                  o: {
                    x: [
                      0.167,
                      0.167
                    ],
                    y: [
                      0.23,
                      0.23
                    ]
                  },
                  t: 36,
                  s: [
                    100.101,
                    100.101
                  ]
                },
                {
                  t: 37.0000015070409,
                  s: [
                    100.034,
                    100.034
                  ]
                }
              ],
              ix: 3
            },
            r: {
              a: 0,
              k: 0,
              ix: 6
            },
            o: {
              a: 0,
              k: 100,
              ix: 7
            },
            sk: {
              a: 0,
              k: 0,
              ix: 4
            },
            sa: {
              a: 0,
              k: 0,
              ix: 5
            },
            nm: "Transform"
          }
        ],
        nm: "Group 1",
        np: 1,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: "ADBE Vector Group",
        hd: !1
      },
      {
        ty: "st",
        c: {
          a: 0,
          k: [
            1,
            0.509803950787,
            0.352941185236,
            1
          ],
          ix: 3
        },
        o: {
          a: 0,
          k: 100,
          ix: 4
        },
        w: {
          a: 0,
          k: 9.2,
          ix: 5
        },
        lc: 1,
        lj: 1,
        ml: 10,
        bm: 0,
        nm: "Stroke 1",
        mn: "ADBE Vector Graphic - Stroke",
        hd: !1
      }
    ],
    ip: 0,
    op: 49.0000019958109,
    st: -79.000003217736,
    bm: 0
  },
  {
    ddd: 0,
    ind: 2,
    ty: 4,
    nm: "eclipse_3",
    sr: 1,
    ks: {
      o: {
        a: 1,
        k: [
          {
            t: 0,
            s: [
              0
            ],
            h: 1
          },
          {
            t: 26.0000010590017,
            s: [
              100
            ],
            h: 1
          }
        ],
        ix: 11
      },
      r: {
        a: 0,
        k: 0,
        ix: 10
      },
      p: {
        a: 0,
        k: [
          32.048,
          32,
          0
        ],
        ix: 2,
        l: 2
      },
      a: {
        a: 0,
        k: [
          0,
          0,
          0
        ],
        ix: 1,
        l: 2
      },
      s: {
        a: 0,
        k: [
          64,
          64,
          100
        ],
        ix: 6,
        l: 2
      }
    },
    ao: 0,
    hasMask: !0,
    masksProperties: [
      {
        inv: !1,
        mode: "a",
        pt: {
          a: 0,
          k: {
            i: [
              [
                24.196,
                0
              ],
              [
                0,
                -24.24
              ],
              [
                -24.196,
                0
              ],
              [
                0,
                24.24
              ]
            ],
            o: [
              [
                -24.196,
                0
              ],
              [
                0,
                24.24
              ],
              [
                24.196,
                0
              ],
              [
                0,
                -24.24
              ]
            ],
            v: [
              [
                0,
                -43.891
              ],
              [
                -43.811,
                0
              ],
              [
                0,
                43.891
              ],
              [
                43.811,
                0
              ]
            ],
            c: !0
          },
          ix: 1
        },
        o: {
          a: 0,
          k: 100,
          ix: 3
        },
        x: {
          a: 0,
          k: 0,
          ix: 4
        },
        nm: "Mask 1"
      }
    ],
    shapes: [
      {
        ty: "gr",
        it: [
          {
            ind: 0,
            ty: "sh",
            ix: 1,
            ks: {
              a: 1,
              k: [
                {
                  i: {
                    x: 0,
                    y: 1
                  },
                  o: {
                    x: 0.577,
                    y: 0
                  },
                  t: 26,
                  s: [
                    {
                      i: [
                        [
                          23.939,
                          2e-3
                        ],
                        [
                          2e-3,
                          -23.519
                        ],
                        [
                          23.939,
                          -2e-3
                        ]
                      ],
                      o: [
                        [
                          23.939,
                          2e-3
                        ],
                        [
                          -2e-3,
                          23.704
                        ],
                        [
                          23.939,
                          -2e-3
                        ]
                      ],
                      v: [
                        [
                          -0.075,
                          -43.891
                        ],
                        [
                          43.811,
                          0
                        ],
                        [
                          -0.075,
                          43.891
                        ]
                      ],
                      c: !1
                    }
                  ]
                },
                {
                  t: 48.0000019550801,
                  s: [
                    {
                      i: [
                        [
                          -23.889,
                          2e-3
                        ],
                        [
                          0,
                          -15.37
                        ],
                        [
                          0,
                          -20.187
                        ]
                      ],
                      o: [
                        [
                          0,
                          20.372
                        ],
                        [
                          0,
                          15.556
                        ],
                        [
                          -23.889,
                          -2e-3
                        ]
                      ],
                      v: [
                        [
                          -0.075,
                          -43.891
                        ],
                        [
                          -0.074,
                          0
                        ],
                        [
                          -0.075,
                          43.891
                        ]
                      ],
                      c: !1
                    }
                  ]
                }
              ],
              ix: 2
            },
            nm: "Path 1",
            mn: "ADBE Vector Shape - Group",
            hd: !1
          },
          {
            ty: "st",
            c: {
              a: 0,
              k: [
                1,
                0.509803921569,
                0.352941176471,
                1
              ],
              ix: 3
            },
            o: {
              a: 0,
              k: 100,
              ix: 4
            },
            w: {
              a: 0,
              k: 9.2,
              ix: 5
            },
            lc: 2,
            lj: 2,
            bm: 0,
            nm: "Stroke 1",
            mn: "ADBE Vector Graphic - Stroke",
            hd: !1
          },
          {
            ty: "tr",
            p: {
              a: 0,
              k: [
                0,
                0
              ],
              ix: 2
            },
            a: {
              a: 0,
              k: [
                0,
                0
              ],
              ix: 1
            },
            s: {
              a: 0,
              k: [
                100,
                100
              ],
              ix: 3
            },
            r: {
              a: 0,
              k: 0,
              ix: 6
            },
            o: {
              a: 0,
              k: 100,
              ix: 7
            },
            sk: {
              a: 0,
              k: 0,
              ix: 4
            },
            sa: {
              a: 0,
              k: 0,
              ix: 5
            },
            nm: "Transform"
          }
        ],
        nm: "Group 1",
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: "ADBE Vector Group",
        hd: !1
      }
    ],
    ip: 0,
    op: 49.0000019958109,
    st: -91.000003706506,
    bm: 0
  },
  {
    ddd: 0,
    ind: 3,
    ty: 4,
    nm: "eclipse_2",
    sr: 1,
    ks: {
      o: {
        a: 1,
        k: [
          {
            t: 0,
            s: [
              100
            ],
            h: 1
          },
          {
            t: 12.00000048877,
            s: [
              0
            ],
            h: 1
          }
        ],
        ix: 11
      },
      r: {
        a: 0,
        k: 0,
        ix: 10
      },
      p: {
        a: 0,
        k: [
          32.048,
          32,
          0
        ],
        ix: 2,
        l: 2
      },
      a: {
        a: 0,
        k: [
          0,
          0,
          0
        ],
        ix: 1,
        l: 2
      },
      s: {
        a: 0,
        k: [
          64,
          64,
          100
        ],
        ix: 6,
        l: 2
      }
    },
    ao: 0,
    hasMask: !0,
    masksProperties: [
      {
        inv: !1,
        mode: "a",
        pt: {
          a: 0,
          k: {
            i: [
              [
                24.196,
                0
              ],
              [
                0,
                -24.24
              ],
              [
                -24.196,
                0
              ],
              [
                0,
                24.24
              ]
            ],
            o: [
              [
                -24.196,
                0
              ],
              [
                0,
                24.24
              ],
              [
                24.196,
                0
              ],
              [
                0,
                -24.24
              ]
            ],
            v: [
              [
                0,
                -43.891
              ],
              [
                -43.811,
                0
              ],
              [
                0,
                43.891
              ],
              [
                43.811,
                0
              ]
            ],
            c: !0
          },
          ix: 1
        },
        o: {
          a: 0,
          k: 100,
          ix: 3
        },
        x: {
          a: 0,
          k: 0,
          ix: 4
        },
        nm: "Mask 1"
      }
    ],
    shapes: [
      {
        ty: "gr",
        it: [
          {
            ind: 0,
            ty: "sh",
            ix: 1,
            ks: {
              a: 1,
              k: [
                {
                  i: {
                    x: 0.729,
                    y: 1
                  },
                  o: {
                    x: 0.721,
                    y: 0
                  },
                  t: 0,
                  s: [
                    {
                      i: [
                        [
                          -23.889,
                          2e-3
                        ],
                        [
                          0,
                          -15.37
                        ],
                        [
                          0,
                          -20.187
                        ]
                      ],
                      o: [
                        [
                          0,
                          20.372
                        ],
                        [
                          0,
                          15.556
                        ],
                        [
                          -23.889,
                          -2e-3
                        ]
                      ],
                      v: [
                        [
                          -0.075,
                          -43.891
                        ],
                        [
                          -0.074,
                          0
                        ],
                        [
                          -0.075,
                          43.891
                        ]
                      ],
                      c: !1
                    }
                  ]
                },
                {
                  t: 12.00000048877,
                  s: [
                    {
                      i: [
                        [
                          -23.889,
                          2e-3
                        ],
                        [
                          -2e-3,
                          -23.519
                        ],
                        [
                          -23.889,
                          -2e-3
                        ]
                      ],
                      o: [
                        [
                          -23.889,
                          2e-3
                        ],
                        [
                          2e-3,
                          23.704
                        ],
                        [
                          -23.889,
                          -2e-3
                        ]
                      ],
                      v: [
                        [
                          -0.075,
                          -43.891
                        ],
                        [
                          -43.87,
                          0
                        ],
                        [
                          -0.075,
                          43.891
                        ]
                      ],
                      c: !1
                    }
                  ],
                  h: 1
                }
              ],
              ix: 2
            },
            nm: "Path 1",
            mn: "ADBE Vector Shape - Group",
            hd: !1
          },
          {
            ty: "st",
            c: {
              a: 0,
              k: [
                1,
                0.509803921569,
                0.352941176471,
                1
              ],
              ix: 3
            },
            o: {
              a: 0,
              k: 100,
              ix: 4
            },
            w: {
              a: 0,
              k: 9.2,
              ix: 5
            },
            lc: 2,
            lj: 2,
            bm: 0,
            nm: "Stroke 1",
            mn: "ADBE Vector Graphic - Stroke",
            hd: !1
          },
          {
            ty: "tr",
            p: {
              a: 0,
              k: [
                0,
                0
              ],
              ix: 2
            },
            a: {
              a: 0,
              k: [
                0,
                0
              ],
              ix: 1
            },
            s: {
              a: 0,
              k: [
                100,
                100
              ],
              ix: 3
            },
            r: {
              a: 0,
              k: 0,
              ix: 6
            },
            o: {
              a: 0,
              k: 100,
              ix: 7
            },
            sk: {
              a: 0,
              k: 0,
              ix: 4
            },
            sa: {
              a: 0,
              k: 0,
              ix: 5
            },
            nm: "Transform"
          }
        ],
        nm: "Group 1",
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: "ADBE Vector Group",
        hd: !1
      }
    ],
    ip: 0,
    op: 49.0000019958109,
    st: -91.000003706506,
    bm: 0
  },
  {
    ddd: 0,
    ind: 4,
    ty: 4,
    nm: "core 2",
    sr: 1,
    ks: {
      o: {
        a: 1,
        k: [
          {
            t: 0,
            s: [
              100
            ],
            h: 1
          },
          {
            t: 12.00000048877,
            s: [
              0
            ],
            h: 1
          }
        ],
        ix: 11
      },
      r: {
        a: 0,
        k: 0,
        ix: 10
      },
      p: {
        a: 0,
        k: [
          32,
          32,
          0
        ],
        ix: 2,
        l: 2
      },
      a: {
        a: 0,
        k: [
          -1.982,
          -12.035,
          0
        ],
        ix: 1,
        l: 2
      },
      s: {
        a: 0,
        k: [
          64,
          64,
          100
        ],
        ix: 6,
        l: 2
      }
    },
    ao: 0,
    hasMask: !0,
    masksProperties: [
      {
        inv: !1,
        mode: "a",
        pt: {
          a: 1,
          k: [
            {
              i: {
                x: 0.729,
                y: 1
              },
              o: {
                x: 0.721,
                y: 0
              },
              t: 0,
              s: [
                {
                  i: [
                    [
                      0,
                      -15.185
                    ],
                    [
                      0,
                      -20.146
                    ],
                    [
                      0,
                      24.218
                    ],
                    [
                      -24.218,
                      0
                    ]
                  ],
                  o: [
                    [
                      0,
                      15.37
                    ],
                    [
                      -24.218,
                      0
                    ],
                    [
                      0,
                      -24.218
                    ],
                    [
                      0,
                      18.294
                    ]
                  ],
                  v: [
                    [
                      -1.981,
                      -12.093
                    ],
                    [
                      -1.983,
                      31.798
                    ],
                    [
                      -45.781,
                      -12.093
                    ],
                    [
                      -1.983,
                      -55.983
                    ]
                  ],
                  c: !0
                }
              ]
            },
            {
              t: 11.0000004480392,
              s: [
                {
                  i: [
                    [
                      -0.036,
                      -23.646
                    ],
                    [
                      -23.964,
                      -0.088
                    ],
                    [
                      0.021,
                      23.576
                    ],
                    [
                      -23.871,
                      0.019
                    ]
                  ],
                  o: [
                    [
                      -0.036,
                      23.576
                    ],
                    [
                      -23.964,
                      -0.088
                    ],
                    [
                      -0.021,
                      -23.646
                    ],
                    [
                      -23.871,
                      0.019
                    ]
                  ],
                  v: [
                    [
                      -45.743,
                      -11.907
                    ],
                    [
                      -1.907,
                      31.943
                    ],
                    [
                      -45.757,
                      -11.907
                    ],
                    [
                      -2,
                      -55.943
                    ]
                  ],
                  c: !0
                }
              ],
              h: 1
            }
          ],
          ix: 1
        },
        o: {
          a: 0,
          k: 100,
          ix: 3
        },
        x: {
          a: 0,
          k: 0,
          ix: 4
        },
        nm: "Mask 1"
      }
    ],
    shapes: [
      {
        ty: "gr",
        it: [
          {
            ind: 0,
            ty: "sh",
            ix: 1,
            ks: {
              a: 0,
              k: {
                i: [
                  [
                    0,
                    -11.598
                  ],
                  [
                    11.598,
                    0
                  ],
                  [
                    0,
                    11.598
                  ],
                  [
                    -11.598,
                    0
                  ]
                ],
                o: [
                  [
                    0,
                    11.598
                  ],
                  [
                    -11.598,
                    0
                  ],
                  [
                    0,
                    -11.598
                  ],
                  [
                    11.598,
                    0
                  ]
                ],
                v: [
                  [
                    19.018,
                    -12.035
                  ],
                  [
                    -1.982,
                    8.965
                  ],
                  [
                    -22.982,
                    -12.035
                  ],
                  [
                    -1.982,
                    -33.035
                  ]
                ],
                c: !0
              },
              ix: 2
            },
            nm: "Path 1",
            mn: "ADBE Vector Shape - Group",
            hd: !1
          },
          {
            ty: "tr",
            p: {
              a: 0,
              k: [
                -1.985,
                -12.043
              ],
              ix: 2
            },
            a: {
              a: 0,
              k: [
                -1.977,
                -11.993
              ],
              ix: 1
            },
            s: {
              a: 0,
              k: [
                100.034,
                100.034
              ],
              ix: 3
            },
            r: {
              a: 0,
              k: 0,
              ix: 6
            },
            o: {
              a: 0,
              k: 100,
              ix: 7
            },
            sk: {
              a: 0,
              k: 0,
              ix: 4
            },
            sa: {
              a: 0,
              k: 0,
              ix: 5
            },
            nm: "Transform"
          }
        ],
        nm: "Group 1",
        np: 1,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: "ADBE Vector Group",
        hd: !1
      },
      {
        ty: "st",
        c: {
          a: 0,
          k: [
            1,
            0.509803950787,
            0.352941185236,
            1
          ],
          ix: 3
        },
        o: {
          a: 0,
          k: 100,
          ix: 4
        },
        w: {
          a: 0,
          k: 9.2,
          ix: 5
        },
        lc: 1,
        lj: 1,
        ml: 10,
        bm: 0,
        nm: "Stroke 1",
        mn: "ADBE Vector Graphic - Stroke",
        hd: !1
      }
    ],
    ip: 0,
    op: 12.00000048877,
    st: -79.000003217736,
    bm: 0
  },
  {
    ddd: 0,
    ind: 6,
    ty: 4,
    nm: "body",
    sr: 1,
    ks: {
      o: {
        a: 0,
        k: 100,
        ix: 11
      },
      r: {
        a: 0,
        k: 0,
        ix: 10
      },
      p: {
        a: 0,
        k: [
          32.048,
          32,
          0
        ],
        ix: 2,
        l: 2
      },
      a: {
        a: 0,
        k: [
          0,
          0,
          0
        ],
        ix: 1,
        l: 2
      },
      s: {
        a: 0,
        k: [
          64,
          64,
          100
        ],
        ix: 6,
        l: 2
      }
    },
    ao: 0,
    shapes: [
      {
        ty: "gr",
        it: [
          {
            ind: 0,
            ty: "sh",
            ix: 1,
            ks: {
              a: 0,
              k: {
                i: [
                  [
                    2e-3,
                    -23.519
                  ],
                  [
                    24.074,
                    2e-3
                  ],
                  [
                    -1e-3,
                    23.704
                  ],
                  [
                    -23.883,
                    4e-3
                  ]
                ],
                o: [
                  [
                    -2e-3,
                    23.704
                  ],
                  [
                    -23.889,
                    -2e-3
                  ],
                  [
                    1e-3,
                    -23.519
                  ],
                  [
                    24.08,
                    -4e-3
                  ]
                ],
                v: [
                  [
                    43.815,
                    0
                  ],
                  [
                    -0.075,
                    43.891
                  ],
                  [
                    -43.873,
                    0
                  ],
                  [
                    -0.081,
                    -43.885
                  ]
                ],
                c: !0
              },
              ix: 2
            },
            nm: "Path 1",
            mn: "ADBE Vector Shape - Group",
            hd: !1
          },
          {
            ty: "st",
            c: {
              a: 0,
              k: [
                1,
                0.509803950787,
                0.352941185236,
                1
              ],
              ix: 3
            },
            o: {
              a: 0,
              k: 100,
              ix: 4
            },
            w: {
              a: 0,
              k: 9.2,
              ix: 5
            },
            lc: 1,
            lj: 1,
            ml: 10,
            bm: 0,
            nm: "Stroke 1",
            mn: "ADBE Vector Graphic - Stroke",
            hd: !1
          },
          {
            ty: "tr",
            p: {
              a: 0,
              k: [
                0,
                0
              ],
              ix: 2
            },
            a: {
              a: 0,
              k: [
                0,
                0
              ],
              ix: 1
            },
            s: {
              a: 0,
              k: [
                100,
                100
              ],
              ix: 3
            },
            r: {
              a: 0,
              k: 0,
              ix: 6
            },
            o: {
              a: 0,
              k: 100,
              ix: 7
            },
            sk: {
              a: 0,
              k: 0,
              ix: 4
            },
            sa: {
              a: 0,
              k: 0,
              ix: 5
            },
            nm: "Transform"
          }
        ],
        nm: "Group 1",
        np: 2,
        cix: 2,
        bm: 0,
        ix: 1,
        mn: "ADBE Vector Group",
        hd: !1
      }
    ],
    ip: 0,
    op: 49.0000019958109,
    st: -75.0000030548126,
    bm: 0
  }
], G = [], S = {
  v: m,
  fr: p,
  ip: y,
  op: c,
  w: l,
  h: d,
  nm: h,
  ddd: f,
  assets: u,
  layers: v,
  markers: G
}, b = e`
  position: fixed;
  z-index: 9999;
`, A = s.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  gap: ${n("xs")};

  ${(a) => a.$variant === "splash" ? b : void 0}
`, D = s.div`
  display: flex;
  width: 48px;
  height: 48px;
`, $ = (a) => {
  const {
    variant: t = "primary",
    loadingText: o = "Loading...",
    className: x
  } = a;
  return /* @__PURE__ */ i.jsxs(
    A,
    {
      $variant: t,
      className: x,
      children: [
        /* @__PURE__ */ i.jsx(D, { children: /* @__PURE__ */ i.jsx(
          r,
          {
            animationData: S
          }
        ) }),
        /* @__PURE__ */ i.jsx(
          k,
          {
            size: "sm",
            weight: "medium",
            children: o
          }
        )
      ]
    }
  );
};
export {
  $ as default
};
