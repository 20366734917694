import { css as o } from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-atf5CkC7.js";
import { getPaletteColors as i, getPaletteForeground as r } from "../Theme/utils.es.js";
import "react";
import "../../jsx-runtime-B6kdoens.js";
import "../../CssBaseline-XoOio97s.js";
const g = {
  xs: o`
    width: 20px;
    height: 20px;

    > svg {
      width: 10px;
      height: 10px;

      #Icon {
        stroke-width: 3.66;
      }
    }
  `,
  sm: o`
    width: 24px;
    height: 24px;

    > svg {
      width: 14px;
      height: 14px;

      #Icon {
        stroke-width: 3.33;
      }
    }
  `,
  md: o`
    width: 28px;
    height: 28px;

    > svg {
      width: 18px;
      height: 18px;

      #Icon {
        stroke-width: 3;
      }
    }
  `,
  lg: o`
    width: 32px;
    height: 32px;

    > svg {
      width: 22px;
      height: 22px;

      #Icon {
        stroke-width: 2.66;
      }
    }
  `
}, e = {
  xs: o`
    border: 2px solid;
  `,
  sm: o`
    border: 2px solid;
  `,
  md: o`
    border: 2.33px solid;
  `,
  lg: o`
    border: 2.66px solid;
  `
}, n = {
  ghost: o`
    color: ${(t) => i(`${t.$color}.600`)};
    background-color: ${(t) => i(`${t.$color}.100`)};
  `,
  outlined: o`
    color: ${(t) => i(`${t.$color}.600`)};

    ${(t) => e[t.$size]}
  `,
  contained: o`
    color: ${r("white")};
    background-color: ${(t) => i(`${t.$color}.600`)};
  `
};
export {
  g as sizeStyles,
  n as variantStyles
};
