import { j as n } from "./jsx-runtime-B6kdoens.js";
import c from "styled-components";
import { t as d, a as h, b as $, c as g } from "./styles-DaQVIHg4.js";
const m = c.span`
  ${(t) => t.$variant === "text" ? d[t.$size] : h[t.$size]}
  
  ${(t) => $[t.$weight]};
  
  ${(t) => g.colors[t.$color]};

  &[data-is-truncated="true"] {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &[data-as="span"] {
    display: block;
  }
`, f = (t) => {
  const {
    variant: e = "text",
    size: s = "md",
    weight: r = "regular",
    color: o = "primary",
    as: a = "span",
    className: i,
    isTruncated: l = !1,
    children: p,
    ...y
  } = t;
  return /* @__PURE__ */ n.jsx(
    m,
    {
      ...y,
      className: i,
      as: a,
      $variant: e,
      $size: s,
      $weight: r,
      $color: o,
      "data-is-truncated": l,
      "data-as": a,
      children: p
    }
  );
};
export {
  f as T
};
