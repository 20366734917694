
type RootParams = {
  tenantID: string;
};

export type EnterpriseNavigationContext = {
  root: {
    params: RootParams;
  };
  admin: {
    params: RootParams;
  };
  adminPositions: {
    params: RootParams;
  };
  adminPositionsDefaultReviewers: {
    params: RootParams;
  };
  adminRateCards: {
    params: RootParams;
  };
  adminRateCardNew: {
    params: RootParams;
  };
  adminRateCard: {
    params: RootParams & {
      rateCardID: string;
    };
  };
  adminRateCardDetails: EnterpriseNavigationContext['adminRateCard'];
  positions: {
    params: RootParams;
  };
  positionNew: {
    params: RootParams;
  };
  position: {
    params: RootParams & {
      positionID: string;
    };
  };
  positionDetails: EnterpriseNavigationContext['position'];
  positionRate: EnterpriseNavigationContext['position'];
  positionFees: EnterpriseNavigationContext['position'];
  positionStakeholders: EnterpriseNavigationContext['position'];
  positionCostSummary: EnterpriseNavigationContext['position'];
};

const enterpriseLinks = {
  root: (context: EnterpriseNavigationContext['root']) => {
    return `/${context.params.tenantID}` as const;
  },
  admin: (context: EnterpriseNavigationContext['admin']) => {
    return `${enterpriseLinks.root(context)}/admin` as const;
  },
  adminPositions: (context: EnterpriseNavigationContext['adminPositions']) => {
    return `${enterpriseLinks.admin(context)}/positions` as const;
  },
  adminPositionsDefaultReviewers: (context: EnterpriseNavigationContext['adminPositionsDefaultReviewers']) => {
    return `${enterpriseLinks.adminPositions(context)}/default-reviewers` as const;
  },
  adminRateCards: (context: EnterpriseNavigationContext['adminRateCards']) => {
    return `${enterpriseLinks.admin(context)}/rate-cards` as const;
  },
  adminRateCardNew: (context: EnterpriseNavigationContext['adminRateCardNew']) => {
    return `${enterpriseLinks.adminRateCards(context)}/new` as const;
  },
  adminRateCard: (context: EnterpriseNavigationContext['adminRateCard']) => {
    return `${enterpriseLinks.adminRateCards(context)}/${context.params.rateCardID}` as const;
  },
  adminRateCardDetails: (context: EnterpriseNavigationContext['adminRateCardDetails']) => {
    return `${enterpriseLinks.adminRateCard(context)}/details`;
  },
  positions: (context: EnterpriseNavigationContext['positions']) => {
    return `${enterpriseLinks.root(context)}/positions` as const;
  },
  positionNew: (context: EnterpriseNavigationContext['positionNew']) => {
    return `${enterpriseLinks.positions(context)}/new` as const;
  },
  position: (context: EnterpriseNavigationContext['position']) => {
    return `${enterpriseLinks.positions(context)}/${context.params.positionID}` as const;
  },
  positionDetails: (context: EnterpriseNavigationContext['positionDetails']) => {
    return `${enterpriseLinks.position(context)}/details`;
  },
  positionRate: (context: EnterpriseNavigationContext['positionRate']) => {
    return `${enterpriseLinks.position(context)}/rate`;
  },
  positionFees: (context: EnterpriseNavigationContext['positionFees']) => {
    return `${enterpriseLinks.position(context)}/fees`;
  },
  positionStakeholders: (context: EnterpriseNavigationContext['positionStakeholders']) => {
    return `${enterpriseLinks.position(context)}/stakeholders`;
  },
  positionCostSummary: (context: EnterpriseNavigationContext['positionCostSummary']) => {
    return `${enterpriseLinks.position(context)}/cost-summary`;
  },
} as const;

export default enterpriseLinks;
