import e, { invertColors as M, invertColor as N } from "./components/Theme/colors.es.js";
function x() {
  return x = Object.assign ? Object.assign.bind() : function(a) {
    for (var r = 1; r < arguments.length; r++) {
      var n = arguments[r];
      for (var t in n)
        Object.prototype.hasOwnProperty.call(n, t) && (a[t] = n[t]);
    }
    return a;
  }, x.apply(this, arguments);
}
function G(a) {
  if (a === void 0)
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  return a;
}
function m(a, r) {
  return m = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function(t, o) {
    return t.__proto__ = o, t;
  }, m(a, r);
}
function Y(a, r) {
  a.prototype = Object.create(r.prototype), a.prototype.constructor = a, m(a, r);
}
function F(a) {
  return F = Object.setPrototypeOf ? Object.getPrototypeOf.bind() : function(n) {
    return n.__proto__ || Object.getPrototypeOf(n);
  }, F(a);
}
function B(a) {
  try {
    return Function.toString.call(a).indexOf("[native code]") !== -1;
  } catch {
    return typeof a == "function";
  }
}
function D() {
  if (typeof Reflect > "u" || !Reflect.construct || Reflect.construct.sham) return !1;
  if (typeof Proxy == "function") return !0;
  try {
    return Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function() {
    })), !0;
  } catch {
    return !1;
  }
}
function w(a, r, n) {
  return D() ? w = Reflect.construct.bind() : w = function(o, s, i) {
    var f = [null];
    f.push.apply(f, s);
    var l = Function.bind.apply(o, f), d = new l();
    return i && m(d, i.prototype), d;
  }, w.apply(null, arguments);
}
function R(a) {
  var r = typeof Map == "function" ? /* @__PURE__ */ new Map() : void 0;
  return R = function(t) {
    if (t === null || !B(t)) return t;
    if (typeof t != "function")
      throw new TypeError("Super expression must either be null or a function");
    if (typeof r < "u") {
      if (r.has(t)) return r.get(t);
      r.set(t, o);
    }
    function o() {
      return w(t, arguments, F(this).constructor);
    }
    return o.prototype = Object.create(t.prototype, {
      constructor: {
        value: o,
        enumerable: !1,
        writable: !0,
        configurable: !0
      }
    }), m(o, t);
  }, R(a);
}
var L = {
  1: `Passed invalid arguments to hsl, please pass multiple numbers e.g. hsl(360, 0.75, 0.4) or an object e.g. rgb({ hue: 255, saturation: 0.4, lightness: 0.75 }).

`,
  2: `Passed invalid arguments to hsla, please pass multiple numbers e.g. hsla(360, 0.75, 0.4, 0.7) or an object e.g. rgb({ hue: 255, saturation: 0.4, lightness: 0.75, alpha: 0.7 }).

`,
  3: `Passed an incorrect argument to a color function, please pass a string representation of a color.

`,
  4: `Couldn't generate valid rgb string from %s, it returned %s.

`,
  5: `Couldn't parse the color string. Please provide the color as a string in hex, rgb, rgba, hsl or hsla notation.

`,
  6: `Passed invalid arguments to rgb, please pass multiple numbers e.g. rgb(255, 205, 100) or an object e.g. rgb({ red: 255, green: 205, blue: 100 }).

`,
  7: `Passed invalid arguments to rgba, please pass multiple numbers e.g. rgb(255, 205, 100, 0.75) or an object e.g. rgb({ red: 255, green: 205, blue: 100, alpha: 0.75 }).

`,
  8: `Passed invalid argument to toColorString, please pass a RgbColor, RgbaColor, HslColor or HslaColor object.

`,
  9: `Please provide a number of steps to the modularScale helper.

`,
  10: `Please pass a number or one of the predefined scales to the modularScale helper as the ratio.

`,
  11: `Invalid value passed as base to modularScale, expected number or em string but got "%s"

`,
  12: `Expected a string ending in "px" or a number passed as the first argument to %s(), got "%s" instead.

`,
  13: `Expected a string ending in "px" or a number passed as the second argument to %s(), got "%s" instead.

`,
  14: `Passed invalid pixel value ("%s") to %s(), please pass a value like "12px" or 12.

`,
  15: `Passed invalid base value ("%s") to %s(), please pass a value like "12px" or 12.

`,
  16: `You must provide a template to this method.

`,
  17: `You passed an unsupported selector state to this method.

`,
  18: `minScreen and maxScreen must be provided as stringified numbers with the same units.

`,
  19: `fromSize and toSize must be provided as stringified numbers with the same units.

`,
  20: `expects either an array of objects or a single object with the properties prop, fromSize, and toSize.

`,
  21: "expects the objects in the first argument array to have the properties `prop`, `fromSize`, and `toSize`.\n\n",
  22: "expects the first argument object to have the properties `prop`, `fromSize`, and `toSize`.\n\n",
  23: `fontFace expects a name of a font-family.

`,
  24: `fontFace expects either the path to the font file(s) or a name of a local copy.

`,
  25: `fontFace expects localFonts to be an array.

`,
  26: `fontFace expects fileFormats to be an array.

`,
  27: `radialGradient requries at least 2 color-stops to properly render.

`,
  28: `Please supply a filename to retinaImage() as the first argument.

`,
  29: `Passed invalid argument to triangle, please pass correct pointingDirection e.g. 'right'.

`,
  30: "Passed an invalid value to `height` or `width`. Please provide a pixel based unit.\n\n",
  31: `The animation shorthand only takes 8 arguments. See the specification for more information: http://mdn.io/animation

`,
  32: `To pass multiple animations please supply them in arrays, e.g. animation(['rotate', '2s'], ['move', '1s'])
To pass a single animation please supply them in simple values, e.g. animation('rotate', '2s')

`,
  33: `The animation shorthand arrays can only have 8 elements. See the specification for more information: http://mdn.io/animation

`,
  34: `borderRadius expects a radius value as a string or number as the second argument.

`,
  35: `borderRadius expects one of "top", "bottom", "left" or "right" as the first argument.

`,
  36: `Property must be a string value.

`,
  37: `Syntax Error at %s.

`,
  38: `Formula contains a function that needs parentheses at %s.

`,
  39: `Formula is missing closing parenthesis at %s.

`,
  40: `Formula has too many closing parentheses at %s.

`,
  41: `All values in a formula must have the same unit or be unitless.

`,
  42: `Please provide a number of steps to the modularScale helper.

`,
  43: `Please pass a number or one of the predefined scales to the modularScale helper as the ratio.

`,
  44: `Invalid value passed as base to modularScale, expected number or em/rem string but got %s.

`,
  45: `Passed invalid argument to hslToColorString, please pass a HslColor or HslaColor object.

`,
  46: `Passed invalid argument to rgbToColorString, please pass a RgbColor or RgbaColor object.

`,
  47: `minScreen and maxScreen must be provided as stringified numbers with the same units.

`,
  48: `fromSize and toSize must be provided as stringified numbers with the same units.

`,
  49: `Expects either an array of objects or a single object with the properties prop, fromSize, and toSize.

`,
  50: `Expects the objects in the first argument array to have the properties prop, fromSize, and toSize.

`,
  51: `Expects the first argument object to have the properties prop, fromSize, and toSize.

`,
  52: `fontFace expects either the path to the font file(s) or a name of a local copy.

`,
  53: `fontFace expects localFonts to be an array.

`,
  54: `fontFace expects fileFormats to be an array.

`,
  55: `fontFace expects a name of a font-family.

`,
  56: `linearGradient requries at least 2 color-stops to properly render.

`,
  57: `radialGradient requries at least 2 color-stops to properly render.

`,
  58: `Please supply a filename to retinaImage() as the first argument.

`,
  59: `Passed invalid argument to triangle, please pass correct pointingDirection e.g. 'right'.

`,
  60: "Passed an invalid value to `height` or `width`. Please provide a pixel based unit.\n\n",
  61: `Property must be a string value.

`,
  62: `borderRadius expects a radius value as a string or number as the second argument.

`,
  63: `borderRadius expects one of "top", "bottom", "left" or "right" as the first argument.

`,
  64: `The animation shorthand only takes 8 arguments. See the specification for more information: http://mdn.io/animation.

`,
  65: `To pass multiple animations please supply them in arrays, e.g. animation(['rotate', '2s'], ['move', '1s'])\\nTo pass a single animation please supply them in simple values, e.g. animation('rotate', '2s').

`,
  66: `The animation shorthand arrays can only have 8 elements. See the specification for more information: http://mdn.io/animation.

`,
  67: `You must provide a template to this method.

`,
  68: `You passed an unsupported selector state to this method.

`,
  69: `Expected a string ending in "px" or a number passed as the first argument to %s(), got %s instead.

`,
  70: `Expected a string ending in "px" or a number passed as the second argument to %s(), got %s instead.

`,
  71: `Passed invalid pixel value %s to %s(), please pass a value like "12px" or 12.

`,
  72: `Passed invalid base value %s to %s(), please pass a value like "12px" or 12.

`,
  73: `Please provide a valid CSS variable.

`,
  74: `CSS variable not found and no default was provided.

`,
  75: `important requires a valid style object, got a %s instead.

`,
  76: `fromSize and toSize must be provided as stringified numbers with the same units as minScreen and maxScreen.

`,
  77: `remToPx expects a value in "rem" but you provided it in "%s".

`,
  78: `base must be set in "px" or "%" but you set it in "%s".
`
};
function W() {
  for (var a = arguments.length, r = new Array(a), n = 0; n < a; n++)
    r[n] = arguments[n];
  var t = r[0], o = [], s;
  for (s = 1; s < r.length; s += 1)
    o.push(r[s]);
  return o.forEach(function(i) {
    t = t.replace(/%[a-z]/, i);
  }), t;
}
var u = /* @__PURE__ */ function(a) {
  Y(r, a);
  function r(n) {
    var t;
    if (process.env.NODE_ENV === "production")
      t = a.call(this, "An error occurred. See https://github.com/styled-components/polished/blob/main/src/internalHelpers/errors.md#" + n + " for more information.") || this;
    else {
      for (var o = arguments.length, s = new Array(o > 1 ? o - 1 : 0), i = 1; i < o; i++)
        s[i - 1] = arguments[i];
      t = a.call(this, W.apply(void 0, [L[n]].concat(s))) || this;
    }
    return G(t);
  }
  return r;
}(/* @__PURE__ */ R(Error));
function I(a) {
  return Math.round(a * 255);
}
function J(a, r, n) {
  return I(a) + "," + I(r) + "," + I(n);
}
function h(a, r, n, t) {
  if (t === void 0 && (t = J), r === 0)
    return t(n, n, n);
  var o = (a % 360 + 360) % 360 / 60, s = (1 - Math.abs(2 * n - 1)) * r, i = s * (1 - Math.abs(o % 2 - 1)), f = 0, l = 0, d = 0;
  o >= 0 && o < 1 ? (f = s, l = i) : o >= 1 && o < 2 ? (f = i, l = s) : o >= 2 && o < 3 ? (l = s, d = i) : o >= 3 && o < 4 ? (l = i, d = s) : o >= 4 && o < 5 ? (f = i, d = s) : o >= 5 && o < 6 && (f = s, d = i);
  var g = n - s / 2, b = f + g, p = l + g, P = d + g;
  return t(b, p, P);
}
var H = {
  aliceblue: "f0f8ff",
  antiquewhite: "faebd7",
  aqua: "00ffff",
  aquamarine: "7fffd4",
  azure: "f0ffff",
  beige: "f5f5dc",
  bisque: "ffe4c4",
  black: "000",
  blanchedalmond: "ffebcd",
  blue: "0000ff",
  blueviolet: "8a2be2",
  brown: "a52a2a",
  burlywood: "deb887",
  cadetblue: "5f9ea0",
  chartreuse: "7fff00",
  chocolate: "d2691e",
  coral: "ff7f50",
  cornflowerblue: "6495ed",
  cornsilk: "fff8dc",
  crimson: "dc143c",
  cyan: "00ffff",
  darkblue: "00008b",
  darkcyan: "008b8b",
  darkgoldenrod: "b8860b",
  darkgray: "a9a9a9",
  darkgreen: "006400",
  darkgrey: "a9a9a9",
  darkkhaki: "bdb76b",
  darkmagenta: "8b008b",
  darkolivegreen: "556b2f",
  darkorange: "ff8c00",
  darkorchid: "9932cc",
  darkred: "8b0000",
  darksalmon: "e9967a",
  darkseagreen: "8fbc8f",
  darkslateblue: "483d8b",
  darkslategray: "2f4f4f",
  darkslategrey: "2f4f4f",
  darkturquoise: "00ced1",
  darkviolet: "9400d3",
  deeppink: "ff1493",
  deepskyblue: "00bfff",
  dimgray: "696969",
  dimgrey: "696969",
  dodgerblue: "1e90ff",
  firebrick: "b22222",
  floralwhite: "fffaf0",
  forestgreen: "228b22",
  fuchsia: "ff00ff",
  gainsboro: "dcdcdc",
  ghostwhite: "f8f8ff",
  gold: "ffd700",
  goldenrod: "daa520",
  gray: "808080",
  green: "008000",
  greenyellow: "adff2f",
  grey: "808080",
  honeydew: "f0fff0",
  hotpink: "ff69b4",
  indianred: "cd5c5c",
  indigo: "4b0082",
  ivory: "fffff0",
  khaki: "f0e68c",
  lavender: "e6e6fa",
  lavenderblush: "fff0f5",
  lawngreen: "7cfc00",
  lemonchiffon: "fffacd",
  lightblue: "add8e6",
  lightcoral: "f08080",
  lightcyan: "e0ffff",
  lightgoldenrodyellow: "fafad2",
  lightgray: "d3d3d3",
  lightgreen: "90ee90",
  lightgrey: "d3d3d3",
  lightpink: "ffb6c1",
  lightsalmon: "ffa07a",
  lightseagreen: "20b2aa",
  lightskyblue: "87cefa",
  lightslategray: "789",
  lightslategrey: "789",
  lightsteelblue: "b0c4de",
  lightyellow: "ffffe0",
  lime: "0f0",
  limegreen: "32cd32",
  linen: "faf0e6",
  magenta: "f0f",
  maroon: "800000",
  mediumaquamarine: "66cdaa",
  mediumblue: "0000cd",
  mediumorchid: "ba55d3",
  mediumpurple: "9370db",
  mediumseagreen: "3cb371",
  mediumslateblue: "7b68ee",
  mediumspringgreen: "00fa9a",
  mediumturquoise: "48d1cc",
  mediumvioletred: "c71585",
  midnightblue: "191970",
  mintcream: "f5fffa",
  mistyrose: "ffe4e1",
  moccasin: "ffe4b5",
  navajowhite: "ffdead",
  navy: "000080",
  oldlace: "fdf5e6",
  olive: "808000",
  olivedrab: "6b8e23",
  orange: "ffa500",
  orangered: "ff4500",
  orchid: "da70d6",
  palegoldenrod: "eee8aa",
  palegreen: "98fb98",
  paleturquoise: "afeeee",
  palevioletred: "db7093",
  papayawhip: "ffefd5",
  peachpuff: "ffdab9",
  peru: "cd853f",
  pink: "ffc0cb",
  plum: "dda0dd",
  powderblue: "b0e0e6",
  purple: "800080",
  rebeccapurple: "639",
  red: "f00",
  rosybrown: "bc8f8f",
  royalblue: "4169e1",
  saddlebrown: "8b4513",
  salmon: "fa8072",
  sandybrown: "f4a460",
  seagreen: "2e8b57",
  seashell: "fff5ee",
  sienna: "a0522d",
  silver: "c0c0c0",
  skyblue: "87ceeb",
  slateblue: "6a5acd",
  slategray: "708090",
  slategrey: "708090",
  snow: "fffafa",
  springgreen: "00ff7f",
  steelblue: "4682b4",
  tan: "d2b48c",
  teal: "008080",
  thistle: "d8bfd8",
  tomato: "ff6347",
  turquoise: "40e0d0",
  violet: "ee82ee",
  wheat: "f5deb3",
  white: "fff",
  whitesmoke: "f5f5f5",
  yellow: "ff0",
  yellowgreen: "9acd32"
};
function K(a) {
  if (typeof a != "string") return a;
  var r = a.toLowerCase();
  return H[r] ? "#" + H[r] : a;
}
var Q = /^#[a-fA-F0-9]{6}$/, U = /^#[a-fA-F0-9]{8}$/, X = /^#[a-fA-F0-9]{3}$/, Z = /^#[a-fA-F0-9]{4}$/, k = /^rgb\(\s*(\d{1,3})\s*(?:,)?\s*(\d{1,3})\s*(?:,)?\s*(\d{1,3})\s*\)$/i, V = /^rgb(?:a)?\(\s*(\d{1,3})\s*(?:,)?\s*(\d{1,3})\s*(?:,)?\s*(\d{1,3})\s*(?:,|\/)\s*([-+]?\d*[.]?\d+[%]?)\s*\)$/i, ee = /^hsl\(\s*(\d{0,3}[.]?[0-9]+(?:deg)?)\s*(?:,)?\s*(\d{1,3}[.]?[0-9]?)%\s*(?:,)?\s*(\d{1,3}[.]?[0-9]?)%\s*\)$/i, re = /^hsl(?:a)?\(\s*(\d{0,3}[.]?[0-9]+(?:deg)?)\s*(?:,)?\s*(\d{1,3}[.]?[0-9]?)%\s*(?:,)?\s*(\d{1,3}[.]?[0-9]?)%\s*(?:,|\/)\s*([-+]?\d*[.]?\d+[%]?)\s*\)$/i;
function T(a) {
  if (typeof a != "string")
    throw new u(3);
  var r = K(a);
  if (r.match(Q))
    return {
      red: parseInt("" + r[1] + r[2], 16),
      green: parseInt("" + r[3] + r[4], 16),
      blue: parseInt("" + r[5] + r[6], 16)
    };
  if (r.match(U)) {
    var n = parseFloat((parseInt("" + r[7] + r[8], 16) / 255).toFixed(2));
    return {
      red: parseInt("" + r[1] + r[2], 16),
      green: parseInt("" + r[3] + r[4], 16),
      blue: parseInt("" + r[5] + r[6], 16),
      alpha: n
    };
  }
  if (r.match(X))
    return {
      red: parseInt("" + r[1] + r[1], 16),
      green: parseInt("" + r[2] + r[2], 16),
      blue: parseInt("" + r[3] + r[3], 16)
    };
  if (r.match(Z)) {
    var t = parseFloat((parseInt("" + r[4] + r[4], 16) / 255).toFixed(2));
    return {
      red: parseInt("" + r[1] + r[1], 16),
      green: parseInt("" + r[2] + r[2], 16),
      blue: parseInt("" + r[3] + r[3], 16),
      alpha: t
    };
  }
  var o = k.exec(r);
  if (o)
    return {
      red: parseInt("" + o[1], 10),
      green: parseInt("" + o[2], 10),
      blue: parseInt("" + o[3], 10)
    };
  var s = V.exec(r.substring(0, 50));
  if (s)
    return {
      red: parseInt("" + s[1], 10),
      green: parseInt("" + s[2], 10),
      blue: parseInt("" + s[3], 10),
      alpha: parseFloat("" + s[4]) > 1 ? parseFloat("" + s[4]) / 100 : parseFloat("" + s[4])
    };
  var i = ee.exec(r);
  if (i) {
    var f = parseInt("" + i[1], 10), l = parseInt("" + i[2], 10) / 100, d = parseInt("" + i[3], 10) / 100, g = "rgb(" + h(f, l, d) + ")", b = k.exec(g);
    if (!b)
      throw new u(4, r, g);
    return {
      red: parseInt("" + b[1], 10),
      green: parseInt("" + b[2], 10),
      blue: parseInt("" + b[3], 10)
    };
  }
  var p = re.exec(r.substring(0, 50));
  if (p) {
    var P = parseInt("" + p[1], 10), C = parseInt("" + p[2], 10) / 100, $ = parseInt("" + p[3], 10) / 100, q = "rgb(" + h(P, C, $) + ")", v = k.exec(q);
    if (!v)
      throw new u(4, r, q);
    return {
      red: parseInt("" + v[1], 10),
      green: parseInt("" + v[2], 10),
      blue: parseInt("" + v[3], 10),
      alpha: parseFloat("" + p[4]) > 1 ? parseFloat("" + p[4]) / 100 : parseFloat("" + p[4])
    };
  }
  throw new u(5);
}
var ae = function(r) {
  return r.length === 7 && r[1] === r[2] && r[3] === r[4] && r[5] === r[6] ? "#" + r[1] + r[3] + r[5] : r;
}, z = ae;
function c(a) {
  var r = a.toString(16);
  return r.length === 1 ? "0" + r : r;
}
function j(a) {
  return c(Math.round(a * 255));
}
function ne(a, r, n) {
  return z("#" + j(a) + j(r) + j(n));
}
function S(a, r, n) {
  return h(a, r, n, ne);
}
function te(a, r, n) {
  if (typeof a == "number" && typeof r == "number" && typeof n == "number")
    return S(a, r, n);
  if (typeof a == "object" && r === void 0 && n === void 0)
    return S(a.hue, a.saturation, a.lightness);
  throw new u(1);
}
function oe(a, r, n, t) {
  if (typeof a == "number" && typeof r == "number" && typeof n == "number" && typeof t == "number")
    return t >= 1 ? S(a, r, n) : "rgba(" + h(a, r, n) + "," + t + ")";
  if (typeof a == "object" && r === void 0 && n === void 0 && t === void 0)
    return a.alpha >= 1 ? S(a.hue, a.saturation, a.lightness) : "rgba(" + h(a.hue, a.saturation, a.lightness) + "," + a.alpha + ")";
  throw new u(2);
}
function O(a, r, n) {
  if (typeof a == "number" && typeof r == "number" && typeof n == "number")
    return z("#" + c(a) + c(r) + c(n));
  if (typeof a == "object" && r === void 0 && n === void 0)
    return z("#" + c(a.red) + c(a.green) + c(a.blue));
  throw new u(6);
}
function A(a, r, n, t) {
  if (typeof a == "string" && typeof r == "number") {
    var o = T(a);
    return "rgba(" + o.red + "," + o.green + "," + o.blue + "," + r + ")";
  } else {
    if (typeof a == "number" && typeof r == "number" && typeof n == "number" && typeof t == "number")
      return t >= 1 ? O(a, r, n) : "rgba(" + a + "," + r + "," + n + "," + t + ")";
    if (typeof a == "object" && r === void 0 && n === void 0 && t === void 0)
      return a.alpha >= 1 ? O(a.red, a.green, a.blue) : "rgba(" + a.red + "," + a.green + "," + a.blue + "," + a.alpha + ")";
  }
  throw new u(7);
}
var se = function(r) {
  return typeof r.red == "number" && typeof r.green == "number" && typeof r.blue == "number" && (typeof r.alpha != "number" || typeof r.alpha > "u");
}, ie = function(r) {
  return typeof r.red == "number" && typeof r.green == "number" && typeof r.blue == "number" && typeof r.alpha == "number";
}, fe = function(r) {
  return typeof r.hue == "number" && typeof r.saturation == "number" && typeof r.lightness == "number" && (typeof r.alpha != "number" || typeof r.alpha > "u");
}, de = function(r) {
  return typeof r.hue == "number" && typeof r.saturation == "number" && typeof r.lightness == "number" && typeof r.alpha == "number";
};
function ue(a) {
  if (typeof a != "object") throw new u(8);
  if (ie(a)) return A(a);
  if (se(a)) return O(a);
  if (de(a)) return oe(a);
  if (fe(a)) return te(a);
  throw new u(8);
}
function _(a, r, n) {
  return function() {
    var o = n.concat(Array.prototype.slice.call(arguments));
    return o.length >= r ? a.apply(this, o) : _(a, r, o);
  };
}
function le(a) {
  return _(a, a.length, []);
}
function pe(a, r, n) {
  return Math.max(a, Math.min(r, n));
}
function E(a) {
  if (a === "transparent") return a;
  var r = T(a);
  return ue(x({}, r, {
    red: 255 - r.red,
    green: 255 - r.green,
    blue: 255 - r.blue
  }));
}
function ce(a, r) {
  if (r === "transparent") return r;
  var n = T(r), t = typeof n.alpha == "number" ? n.alpha : 1, o = x({}, n, {
    alpha: pe(0, 1, +(t * 100 - parseFloat(a) * 100).toFixed(2) / 100)
  });
  return A(o);
}
var ge = /* @__PURE__ */ le(ce), me = ge;
const y = {
  base: {
    white: "#ffffff",
    black: "#000000",
    transparent: "transparent"
  },
  text: {
    default: "#000000",
    primary: {
      default: e.gray[900],
      brand: "#ffffff"
    },
    secondary: {
      default: e.gray[700],
      hover: e.gray[800],
      brand: e.brand[700]
    },
    tertiary: {
      default: e.gray[600],
      hover: e.gray[700],
      brand: e.brand[200]
    },
    quaternary: {
      default: e.gray[500],
      brand: e.brand[300]
    },
    white: "#ffffff",
    disabled: e.gray[500],
    placeholder: e.gray[500],
    placeholderSubtle: e.gray[300],
    brand: {
      primary: e.brand[900],
      secondary: e.brand[700],
      tertiary: e.brand[600],
      tertiaryAlt: e.brand[600]
    },
    error: e.error[600],
    warning: e.warning[600],
    success: e.success[600]
  },
  background: {
    default: "#ffffff",
    primary: {
      default: "#ffffff",
      alt: "#ffffff",
      hover: e.gray[50],
      solid: e.gray[950]
    },
    secondary: {
      default: e.gray[50],
      alt: e.gray[50],
      hover: e.gray[100],
      subtle: e.gray[600],
      solid: e.gray[50]
    },
    tertiary: e.gray[100],
    quaternary: e.gray[200],
    active: e.gray[50],
    disabled: {
      default: e.gray[100],
      subtle: e.gray[50]
    },
    overlay: e.gray[950],
    brand: {
      primary: {
        default: e.brand[50],
        alt: e.brand[600]
      },
      secondary: {
        default: e.brand[100]
      },
      solid: {
        default: e.brand[600],
        hover: e.brand[700]
      },
      section: {
        default: e.brand[800],
        subtle: e.brand[700]
      }
    },
    error: {
      primary: e.error[50],
      secondary: e.error[100],
      solid: e.error[600]
    },
    warning: {
      primary: e.warning[50],
      secondary: e.warning[100],
      solid: e.warning[600]
    },
    success: {
      primary: e.success[50],
      secondary: e.success[100],
      solid: e.success[600]
    }
  },
  foreground: {
    primary: {
      default: e.gray[900]
    },
    secondary: {
      default: e.gray[700],
      hover: e.gray[800]
    },
    tertiary: {
      default: e.gray[600],
      hover: e.gray[700]
    },
    quaternary: {
      default: e.gray[500],
      hover: e.gray[600]
    },
    quinary: {
      default: e.gray[400],
      hover: e.gray[500]
    },
    senary: {
      default: e.gray[300]
    },
    septenary: {
      default: e.gray[200]
    },
    white: "#ffffff",
    disabled: {
      default: e.gray[400],
      subtle: e.gray[300]
    },
    brand: {
      primary: {
        default: e.brand[600],
        alt: e.brand[600]
      },
      secondary: {
        default: e.brand[500]
      }
    },
    error: {
      primary: e.error[600],
      secondary: e.error[500]
    },
    warning: {
      primary: e.warning[600],
      secondary: e.warning[500]
    },
    success: {
      primary: e.success[600],
      secondary: e.success[500]
    }
  },
  border: {
    primary: e.gray[300],
    secondary: e.gray[200],
    tertiary: e.gray[100],
    disabled: {
      default: e.gray[300],
      subtle: e.gray[200]
    },
    brand: {
      default: e.brand[300],
      solid: {
        default: e.brand[600],
        alt: e.brand[600]
      }
    },
    error: {
      default: e.error[300],
      solid: e.error[600]
    }
  },
  colors: {
    ...e,
    brandAlt: e.brand
  }
}, be = {
  ...y,
  text: {
    default: E(y.text.default),
    primary: {
      default: e.gray[50],
      brand: e.gray[50]
    },
    secondary: {
      default: e.gray[300],
      hover: e.gray[200],
      brand: e.brand[300]
    },
    tertiary: {
      default: e.gray[400],
      hover: e.gray[300],
      brand: e.brand[400]
    },
    quaternary: {
      default: e.gray[400],
      brand: e.gray[400]
    },
    white: "#ffffff",
    disabled: e.gray[500],
    placeholder: e.gray[400],
    placeholderSubtle: e.gray[700],
    brand: {
      primary: e.gray[50],
      secondary: e.gray[300],
      tertiary: e.gray[400],
      tertiaryAlt: e.gray[50]
    },
    error: e.error[400],
    warning: e.warning[400],
    success: e.success[400]
  },
  background: {
    default: E(y.background.default),
    primary: {
      default: e.gray[950],
      alt: e.gray[900],
      hover: e.gray[800],
      solid: e.gray[900]
    },
    secondary: {
      default: e.gray[900],
      alt: e.gray[950],
      hover: e.gray[800],
      subtle: e.gray[900],
      solid: e.gray[600]
    },
    tertiary: e.gray[800],
    quaternary: e.gray[700],
    active: e.gray[800],
    disabled: {
      default: e.gray[800],
      subtle: e.gray[900]
    },
    overlay: e.gray[800],
    brand: {
      primary: {
        default: e.brand[500],
        alt: e.gray[800]
      },
      secondary: {
        default: e.brand[600]
      },
      solid: {
        default: e.brand[600],
        hover: e.brand[500]
      },
      section: {
        default: e.gray[800],
        subtle: e.gray[950]
      }
    },
    error: {
      primary: e.error[500],
      secondary: e.error[600],
      solid: e.error[600]
    },
    warning: {
      primary: e.warning[500],
      secondary: e.warning[600],
      solid: e.warning[600]
    },
    success: {
      primary: e.success[500],
      secondary: e.success[600],
      solid: e.success[600]
    }
  },
  foreground: {
    primary: {
      default: "#ffffff"
    },
    secondary: {
      default: e.gray[300],
      hover: e.gray[200]
    },
    tertiary: {
      default: e.gray[400],
      hover: e.gray[300]
    },
    quaternary: {
      default: e.gray[400],
      hover: e.gray[300]
    },
    quinary: {
      default: e.gray[500],
      hover: e.gray[400]
    },
    senary: {
      default: e.gray[600]
    },
    septenary: {
      default: e.gray[700]
    },
    white: "#ffffff",
    disabled: {
      default: e.gray[500],
      subtle: e.gray[600]
    },
    brand: {
      primary: {
        default: e.brand[500],
        alt: e.gray[300]
      },
      secondary: {
        default: e.brand[500]
      }
    },
    error: {
      primary: e.error[500],
      secondary: e.error[400]
    },
    warning: {
      primary: e.warning[500],
      secondary: e.warning[400]
    },
    success: {
      primary: e.success[500],
      secondary: e.success[400]
    }
  },
  border: {
    primary: e.gray[700],
    secondary: e.gray[800],
    tertiary: e.gray[800],
    disabled: {
      default: e.gray[700],
      subtle: e.gray[800]
    },
    brand: {
      default: e.brand[400],
      solid: {
        default: e.brand[600],
        alt: e.brand[700]
      }
    },
    error: {
      default: e.error[400],
      solid: e.error[600]
    }
  },
  colors: {
    ...M(y.colors),
    brandAlt: N(e.gray)
  }
}, he = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  darkPalette: be,
  default: y,
  lightPalette: y
}, Symbol.toStringTag, { value: "Module" }));
export {
  me as c,
  be as d,
  y as l,
  he as p,
  A as r
};
