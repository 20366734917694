import { j as o } from "./jsx-runtime-B6kdoens.js";
import n from "styled-components";
import "./components/Theme/themes.es.js";
import "./palettes-atf5CkC7.js";
import { getRadii as a } from "./components/Theme/utils.es.js";
import { useMemo as m, Suspense as c } from "react";
import "./CssBaseline-XoOio97s.js";
import p from "./components/Icon/variants.es.js";
import "./components/Icon/countryVariants.es.js";
import { sizeStyles as l, variantStyles as d } from "./components/Icon/checkIconStyles.es.js";
const x = (r) => {
  const {
    variant: t,
    ...i
  } = r, s = m(() => p[t], [t]);
  return /* @__PURE__ */ o.jsx(c, { children: /* @__PURE__ */ o.jsx(s, { ...i }) });
}, f = n.div`
  display: inline-flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;

  border-radius: ${a("full")};

  ${(r) => l[r.$size]};
  ${(r) => d[r.$variant]}
`, I = (r) => {
  const {
    variant: t = "ghost",
    color: i = "brand",
    size: s = "md",
    className: e
  } = r;
  return /* @__PURE__ */ o.jsx(
    f,
    {
      className: e,
      $variant: t,
      $color: i,
      $size: s,
      children: /* @__PURE__ */ o.jsx(x, { variant: "check" })
    }
  );
};
export {
  I as C,
  x as I
};
